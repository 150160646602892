const Fra = {
    text: {
        login_txt1: "※ les personnes de moins de 18 ans ne peuvent pas utiliser",
        choose: "Match",
        bond: "Certifié et payé un dépôt de dix mille dollars",
        img: "Représentation du portrait",
        intention: "経験満 degré de pied",
        casual: "Instructions pour le sexe occasionnel",
        txt1: "Remarque: la plus authentique crochet et boucle de fret aérien est disponible sur cette plate - forme. Pour protéger la vie privée de l'utilisateur, l'adhésion nécessite de contacter le service clientèle ou une recommandation de personnes réussies.",
        txt2: "Consultez le service à la clientèle",
        txt3: "Guide",
        txt4: "Notez le numéro de votre sœur, contactez le service clientèle",
        ok: "Bon",
        cancel: "Annulation",
        txt5: "Pour le bien des membres, seulement 1% des ressources sont affichées sur le site et les mises à jour ne sont pas régulières. Pour des ressources détaillées, veuillez contacter le service clientèle.",
        txt6: "Toutes les informations des membres sur la plateforme sont véridiques et sécurisées. Disponible dans tout le pays, il est également possible de trouver de nouvelles rencontres dans la même rue. Choisissez les femmes que vous aimez et rencontrez - les réellement. Participez maintenant ~",
        txt7: "Renseignements sur les membres",
        txt8: "Match",
        txt9: "Utilisation des Statuts",
        txt10: "Je veux sortir avec elle",
        txt11: "Cette plate - forme offre la recherche déflationniste la plus réaliste. Pour protéger la confidentialité des renseignements personnels, l'inscription ne peut être faite que par des personnes qui contactent le support ou qui acceptent des références de personnes ayant déjà réussi à trouver une expérience déflationniste.",
        txt12: "Actuellement 0 paires en ligne",
        txt13: "44 activé",
        txt14: "Membre 権li",
        txt15: "Événements",
        txt16: "Vidéos des membres",
        txt17: "Le tromper",
        txt18: "Renseignements sur les membres",
        txt19: "Le nouvel id",
        txt20: "Rencontre",
        txt21: "Fin du vote",
        txt22: "Résultats de la période",
        txt23: "Période",
        txt24: "Choisir un numéro de tomate",
        credit_score: "Score de crédit",
        mail: "Lettre de la station",
        txt25: "La mise à niveau nécessite 15 points de pourcentage",
        txt26: "Points personnels",
        txt27: "Point ( 8361;)",
        txt28: "Recharge de points",
        txt29: "Remplacer le point",
        txt30: "Services en ligne",
        txt31: "Introduction",
        txt32: "Détails du point",
        txt33: "Échange de CV",
        txt34: "Enregistrement des données",
        txt35: "Remplacer le mot de passe",
        txt36: "Remplacer le point",

    },
    login: {
        title: "Connexion",
        username: "Veuillez saisir un nom d'utilisateur",
        password: "Veuillez saisir un mot de passe",
        forgetPaw: "Mot de passe oublié",
        registerNow: "Pas de compte? Inscrivez-vous maintenant",
        doLogin: "Connexion",

    },
    profile: {
        portrait: "Écrire vrai",
        video: "Vidéo",
        jianjie: "Profil personnel",
        liji: "Prenez rendez-vous maintenant",
        chongdan: "Plage de charge",
        fd: "Madame.",
        yuyue: "Prendre rendez-vous",
        archives: "Fichier de sœur",
        age: "Âge",
        height: "Hauteur",
        weight: "Poids",
        cup: "Bonnet",
        city: "Zones urbaines",
        area: "Zone de service",
        item: "Lignes de services"
    },
    register:{
        title: "Inscription",
        username: "Veuillez saisir votre nom d'utilisateur (6-12 lettres ou chiffres anglais)",
        password: "Veuillez saisir votre mot de passe (6-12 lettres ou chiffres anglais)",
        code: "Veuillez saisir le code d'invitation",
        agreement: "Je connais et j'accepte les traités de l'Accord d'ouverture de compte",
        chooseAgreement: "Veuillez cocher l'accord d'ouverture de compte ci-dessous!",
        doRegister: "Inscription",

    },
    footer:{
        home : "Page d'accueil",
        game: "Prendre rendez-vous",
        video: "Vidéo",
        mine: "Mon...",

    },
    game:{
        title: "Salle des réservations",
        all: "Tous",

    },
    choose:{
        title: "Concubine élue",
        city: "Encyclopédie de la ville",
        rule: "Cette plate-forme est la plus authentique de l'ensemble du réseau + escorte d'affaires + passion dans la même ville. Afin de garantir la confidentialité personnelle de chaque utilisateur, les clients ne peuvent adhérer qu'en contactant la réceptionniste ou le membre senior de la plate-forme pour recommander le vrai nom.",
        price: "Processus de tarification",
        resource: "Quelles sont les ressources disponibles?",
        character: "Internet rouge, mannequin, hôtesse de l'air, mannequin, étudiant, seulement vous ne pouvez pas y penser, rien ne peut être impossible",
        scope: "Portée du service?",
        location: "L'artillerie est gratuite dans la même ville, et elle est aéroportée dans tout le pays à n'importe quel endroit. Il y a des villes de premier et de deuxième niveau en Chine. Les villes de troisième niveau peuvent également prendre rendez-vous en contactant la réceptionniste.",

    },
    home:{
        recommend: "Tâches recommandées",
        more: "Voir plus",
        popularity: "Classement de popularité",
        hot: "Recommandations populaires",

    },
    lottery:{
        jieguo: "Résultat du vote",
        jiesu: "Fin",
        record: "Dossier de mission",
        rule: "Règles de jeu",
        prompt: "Conseils pour jouer",
        gameplay: "Sélectionnez des numéros dans les formes facultatives et de valeur pour parier",
        explain: "Description des gains",
        wprole: "La valeur totale des trois numéros de loterie est de 11 à 18; La valeur totale de 3 à 10 est petite;",
        example: "Exemples de paris",
        option: "Plan de pari: petit numéro de loterie: 123, c'est-à-dire petit et moyen",
        task: "Liste des tâches",
        money: "Solde disponible",
        submit: "Soumis",
        selection: "Sélection actuelle",
        perNote: "Montant par pari",
        inputMoney: "Veuillez saisir le montant",
        total: "Total",
        note: "Total",
        statistics: "Total",
        empty: "Vider la commande",
        verify: "Confirmation de la soumission",
        issue: "Numéro",
        num: "Numéro de la loterie",
        big: "Grand",
        small: "Petite",
        single: "Simple",
        double: "Double",
        unselected: "Non sélectionné",
        drawLottery: "Tirage réussi, numéro:",
        kefu: "Contactez l'administrateur pour cette tâche!",

    },
    mine:{
        recharge: "Recharge",
        withdraw: "Retraits en espèces",
        myMoney: "Mon portefeuille.",
        detail: "Détails",
        balance: "Solde",
        finance: "Déclaration personnelle",
        order: "Détails du compte",
        record: "Dossier de mission",
        my: "Centre de la personne",
        notice: "Bulletin d &apos; information",
        service: "Service client en ligne",
        score: "Points",
    },
    setting:{
        title: "Configuration",
        basic: "Paramètres des informations de base",
        password: "Connexion Mot de passe",
        capital: "Mot de passe du fonds",
        lang: "Langues",
        quit: "Désactiver la connexion",

    },
    setPayPassword: {
        title: "Définir un mot de passe de fonds",
        opw: "Veuillez saisir le mot de passe du fonds",
        tpw: "Veuillez saisir à nouveau le mot de passe du fonds",

    },
    gameRecord:{
        reject: "Rejeté",
        profit: "Rentable",
        place: "Passer une commande",
        title: "Dossier de mission",
        amountBet: "Montant du pari",
        create: "Heure de la commande",
        update: "Délai de règlement",

    },
    video:{
        title: "Cinéma vidéo",
        play: "Jouer",

    },
    playVideo:{
        play: "Lecture",

    },
    setname:{
        title: "Modifier le vrai nom",
        name: "Nom",
        truename: "Veuillez saisir votre vrai nom",
        hint: "Pour la sécurité de votre compte, le vrai nom doit correspondre au nom de la carte bancaire liée",

    },
    setsex:{
        title: "Modification du sexe",

    },
    bindCard:{
        title: "Remplir la carte de paiement",
        msg: "Veuillez saisir les informations de votre carte de paiement",
        hint: "Cher utilisateur, afin d'assurer la sécurité de vos fonds, veuillez lier votre vrai nom et définir un mot de passe de retrait. Si le nom n'est pas cohérent avec le nom d'ouverture du compte, vous ne pourrez pas retirer de l'argent.",
        card: "Numéro de compte (ordinaire)",
        truecard: "Saisissez le numéro de compte (normal)",
        name: "Nom du compte (nom réel)",
        truename: "Saisissez le nom du compte (nom réel)",
        financial_institution: "Nom de l'institution financière",
        truefinancial_institution: "Entrez le nom de l'institution financière",
        branch: "Nom de la succursale",
        truebranch: "Saisissez le nom de la branche",
        branch_number: "Numéro de la succursale",
        truebranch_number: "Saisissez le numéro de la succursale"
    },
    setbank:{
        title: "Informations sur la carte de paiement",
        bank: "Ajouter une carte bancaire",
        hint: "Astuce: veuillez lier une grande banque commerciale. Si vous devez la modifier, veuillez contacter le service client en ligne",

    },
    setLoginPassword:{
        title: "Modifier le mot de passe de connexion",
        old_password: "Ancien mot de passe",
        old_password_p: "Veuillez saisir l'ancien mot de passe",
        o_new_password: "Nouveau mot de passe",
        o_new_password_p: "Veuillez saisir un nouveau mot de passe",
        t_new_password_p: "Veuillez saisir à nouveau le nouveau mot de passe",

    },
    infomation:{
        title: "Informations de base",
        head: "Avatar",
        select: "Sélectionner un avatar",
        name: "Véritable nom",
        sex: "Sexe",
        man: "Hommes",
        girl: "Femmes",
        unknown: "Inconnu",
        binding: "Informations de liaison",

    },
    withdraw: {
        title: "Centres de retrait",
        record: "Enregistrement des retraits",
        money: "Montant des retraits",
        all: "Tous",
        min: "Limite unique: minimum",
        max: "",
        num: "Retrait en espèces: un jour de retrait en espèces",
        hint: "Temps d'arrivée: Généralement, le temps d'arrivée est d'environ 5 minutes et le plus rapide dans les 2 minutes",
        quota: "Description des limites",
        balance: "Solde",
        withdraw: "Retirez immédiatement",

    },
    withdrawRecord:{
        desc: "Annotations",
        create: "Date de soumission",
        update: "Délai d &apos; examen",

    },
    personalreport:{
        title: "Déclaration personnelle",
        profit: "Montant des bénéfices",
        formula: "Formule de calcul du profit: Montant gagnant-Montant de la mission",
        task: "Montant de la tâche",
        recharge: "Montant de la recharge",
        withdrawal: "Montant des retraits",
        win_money: "Montant de la loterie gagnante",

    },
    service: {
        title: "Service client en ligne",
        relation: "Contact",
        hint: "7 * 24 heures sur 24 pour vous servir de tout cœur",

    },
    msg:{
        "次": "Summary",
        "用户不存在！": "L'utilisateur n'existe pas!",
        "密码错误！": "Mauvais mot de passe!",
        "登录成功！": "Connexion réussie!",
        "邀请码不存在！": "Le code d'invitation n'existe pas!",
        "用户名位数错误！": "Le nombre de bits du nom d'utilisateur est erroné!",
        "密码位数错误！": "Le nombre de chiffres du mot de passe est incorrect!",
        "用户名不能存在中文！": "Le nom d'utilisateur ne peut pas exister en chinois!",
        "邀请码不能为空！": "Le code d'invitation ne peut pas être vide!",
        "注册成功！": "Enregistrement réussi!",
        "注册失败！": "L'enregistrement a échoué!",
        "用户已存在": "L'utilisateur existe déjà",
        "刷新成功！": "Rafraîchissement réussi!",
        "请联系顾问或接待员": "Veuillez contacter un conseiller ou une réceptionniste",
        "金额错误！": "Mauvais montant!",
        "请选号！": "Veuillez choisir un numéro!",
        "请填写金额!": "Veuillez remplir le montant!",
        "余额不足，请联系客服充值！": "Le solde est insuffisant, veuillez contacter le service client pour le recharger!",
        "没有更多了": "Rien de plus.",
        "没有数据": "Données non disponibles",
        "余额不足！": "Solde insuffisant!",
        "扣款失败！": "La déduction a échoué!",
        "下注异常！": "Les paris sont anormaux!",
        "投注成功！": "Le pari est réussi!",
        "参数异常！": "Paramètres anormaux!",
        "获取中": "En cours d'acquisition …",
        "请完成任务单后进入": "Après avoir rempli la liste des tâches",
        "请联系客服充值": "S'il vous plaît contacter le service client pour recharger",
        "请设置收款卡!": "Veuillez configurer une carte de collecte!",
        "功能已禁用!": "Fonction désactivée!",
        "账号下线": "Compte hors ligne",
        "登录/注册": "Connexion/Inscription",
        "登录可享受更多服务！": "Connectez-vous pour plus de services!",
        "未设置": "Non défini",
        "已设置": "Défini",
        "提交": "Soumis",
        "确定": "Déterminé",
        "请填写完整": "Veuillez remplir le formulaire complet.",
        "两次密码不一致！": "Deux fois le mot de passe n'est pas cohérent!",
        "设置提现密码成功！": "Définir le mot de passe de retrait avec succès!",
        "提现密码已设置，需要修改请联系客服": "Le mot de passe de retrait a été défini, veuillez contacter le service client",
        "已绑定": "Liés",
        "无": "Néant",
        "更换头像成功！": "T Changement d'avatar réussi!",
        "更新头像失败！": "La mise à jour de l'avatar a échoué!",
        "请勿重复设置！": "Ne répétez pas les réglages!",
        "设置姓名成功！": "Définir le nom avec succès!",
        "设置姓名失败！": "Échec de la définition du nom!",
        "设置性别成功！": "Set gender success!",
        "设置性别失败！": "Échec de la mise en place du genre!",
        "请设置姓名后再绑定银行卡！": "Veuillez définir votre nom avant de lier votre carte bancaire!",
        "请设置提现密码后再绑定银行卡！": "Veuillez définir un mot de passe de retrait avant de lier la carte bancaire!",
        "确认绑卡": "Confirmez la carte de liaison",
        "请输入银行卡号！": "Veuillez entrer la banque!",
        "请输入银行名称！": "Veuillez saisir le nom de la banque!",
        "设置银行卡成功！": "Configuration réussie de la carte bancaire!",
        "设置银行卡失败！": "La configuration de la carte bancaire a échoué!",
        "旧密码错误": "Erreur d'ancien mot de passe",
        "修改密码成功！": "Modification réussie du mot de passe!",
        "修改密码失败！": "La modification du mot de passe a échoué!",
        "请填写正确的金额": "Veuillez indiquer le montant correct",
        "提现金额不能少于：": "Le montant du retrait ne peut être inférieur à:",
        "提现金额不能大于：": "Le montant du retrait ne peut être supérieur à:",
        "当日提现次数已用完": "Les retraits ont été épuisés ce jour-là",
        "提现成功！": "Récupération réussie!",
        "提现失败！": "Le retrait a échoué!",
        "金额": "Montant",
        "待审核": "En attente d &apos; examen",
        "审核成功": "Réussite de l'audit",
        "审核退回": "Return to the Audit",
        "请充值后观看视频！": "S'il vous plaît regarder la vidéo après la recharge!",
        "释放即可刷新": "Libérez et rafraîchissez-le...",
        "加载中": "En chargement...",
        "下拉即可刷新": "Déplacer pour rafraîchir...",
    },
    setLang:{
        title: "Définir la langue"
    },
    lang:{
        zh: "Chinois",
        jp: "Japon",
        ara: "Langues",
        en: "Anglais",
        spa: "Espagnol",
        it: "Italien",
        de: "Allemand",
        pt: "Portugais",
        fra: "Français",
        nl: "Néerlandais",
        id: "Indonésien",
        hi: "Hindi",
        kor: "Coréen",
        tr: "Turc",
        swe: "Suédois",
        ga: "Irlandais",
        ru: "Russe",
        vie: "Vietnamien",
        th: "Thaï",
        est: "Estonien",
        be: "Biélorusse",
        bul: "Bulgare",
        pl: "Polonais",
        ms: "Malais",
    }
}
export default Fra