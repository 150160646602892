<template>
  <div class="index">
    <div class="van-nav-bar van-hairline--bottom">
      <div class="van-nav-bar__content">
        <div class="van-nav-bar__left van-haptics-feedback">
          <i class="van-badge__wrapper van-icon" style="font-size: 28px;" @click="toMail()">
            <img src="/img/home/info.png" class="van-icon__image">
            <div class="van-badge van-badge--fixed" style="top:2px;font-size: 12px;padding: 2px 5px;" v-if ="mail > 0">{{mail > 100? 99 : mail}}</div>
          </i>
        </div>
        <div class="van-nav-bar__title van-ellipsis" style="color: red">
            {{this.$store.getters.getBaseInfo.name}}
        </div>
        <div class="van-nav-bar__right van-haptics-feedback" @click="MineFun">
          <i class="van-badge__wrapper van-icon" style="font-size: 28px;">
            <img src="/img/home/mine.png" class="van-icon__image">
          </i>
        </div>
      </div>
    </div>

    <div class="notice-bar">
      <van-notice-bar
          class="notice-swipe"
          left-icon="/img/home/notify.png"
          background="#ffffff"
          color="#7e5678"
          :text="this.notice"
      />
      <div class="linear-gradient"></div>
    </div>
    <!--    <div class="my-swiper">
          <div class="my-swipe van-swipe">
            <div class="van-swipe__track" style="transition-duration: 0ms; transform: translateX(0px); width: 385px;">
              <div class="van-swipe-item" style="width: 100%" v-for="(v,key) in banners" :key="key">
                <img :src="v.url" class="swpier-img">
              </div>
            </div>
          </div>
        </div>-->

    <swiper class="banner_swiper" :options="bannerSwiperOption">
      <swiper-slide v-for="(v,key) in banners" :key="key">
        <van-image class="banner_img" round :src="v.url">
          <template v-slot:loading>
            <van-loading type="circular"/>
          </template>
        </van-image>
      </swiper-slide>
    </swiper>
    <div class="card">
      <div class="tp">
        <div>
          <i class="van-badge__wrapper van-icon" style="font-size: 108px;">
            <img
                :src="this.$store.getters.getBaseInfo.ico !==undefined ?this.$store.getters.getBaseInfo.ico:'/img/null.png'"
                class="van-icon__image"></i></div>
        <div>{{ $t("text.txt5") }}</div>
      </div>
      <div class="ft">
        <div>
          {{ $t("text.txt6") }}
        </div>
      </div>
    </div>
    <div class="yixianqian">
      <nav>
        <a :class="{active:active==1}" @click="active=1">{{ $t("text.txt7") }}</a>
        <a @click="getyuyueList()" :class="{active:active==2}">{{  $t("text.txt8") }}</a>
        <a @click="active=3" :class="{active:active==3}">{{ $t("text.txt9") }}</a>
      </nav>
      <div class="list" v-if="active==1">
        <div class="slide" v-for="(item,key) in consort" :key="key" @click="profile(item.id)">
          <div class="image">
            <img :src="item.img_url" alt=""></div>
          <div class="box">
            <div class="tps">
              <p>{{ item.xuanfei_name }}
                <em><i class="van-badge__wrapper van-icon">
                  <img src="/img/dingwei.png" class="van-icon__image"></i> {{ item.address }}</em>
              </p>
              <div class="rz">
                <img src="/img/beauty/rz.png">
              </div>
              <span v-for="(item1,keys) in item.flag" :key="keys">{{ item1 }}</span>
            </div>
            <div class="pj">
              <div class="wx">
                <i class="van-badge__wrapper van-icon" style="font-size: 18px;" v-for="items in item.number"
                   :key="items">
                  <img src="/img/beauty/money.png" class="van-icon__image">
                </i>

              </div>
            </div>
            <a>{{ $t("text.txt10") }}</a></div>
        </div>

      </div>
      <div class="gundong" v-if="active==2">
        <p> {{ activeName }} </p>

        <div class="van-swipe" id="SwipeList1" style="height: 340px;margin-top: 20px;">
          <div class="van-swipe__track van-swipe__track--vertical" :style="{transform:'translateY(-'+i+'px)' }">
            <div class="van-swipe-item"  v-for="(item,key) in yuyueList" :key="key"  :index="key" style="height:40px; color: #fff">
              <div class="topItem van-cell--center">
                <div class="van-cell__title">
                  <span>{{item}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tk" v-if="active==3">
        {{ $t("text.txt11") }}
      </div>

      <div class="van-overlay" style="z-index: 2001;" v-if="mailType"></div>
      <div role="dialog" tabindex="0"  v-if="mailType" class="van-popup van-popup--center van-dialog" aria-labelledby="wedsd" style="z-index: 2002;">
        <div class="van-dialog__header">{{mailText.name}}</div>
        <div class="van-dialog__content">
          <div class="dialog-content" v-html="mailText.text" style="width: 90%;margin: auto;word-wrap: break-word;">

          </div>
        </div>
        <div class="van-hairline--top van-dialog__footer">
          <button type="button" class="van-button van-button--default van-button--large van-dialog__confirm" @click="mailType = !mailType">
            <div class="van-button__content">
              <span class="van-button__text">{{$t("text.ok")}}</span>
            </div>
          </button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      notice: this.$t("msg.获取中"),
      mailType:false,
      i: 0,
      activeName: "",
      mailText: {
        name: "",
        text: ""
      },
      mail: "",
      active: 1,
      banners: [{}],
      basicData: [],
      gameitem: [{}, {}, {}, {}],
      consort: [{}, {}, {}, {}],
      yuyueList: [{}, {}, {}, {}, {}, {}, {}, {}],
      isLoading: false,
      movielistSwiperOption: {
        slidesPerView: 'auto',
        spaceBetween: 0,
        slidesPerGroup: 1,
      },
      bannerSwiperOption: {
        effect: 'coverflow',
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: 'auto',
        speed: 800,
        autoplay: true,
        coverflowEffect: {
          rotate: 50,
          stretch: 10,
          depth: 100,
          modifier: 1,
          slideShadows: true
        }
      },
      intervalId: ""
    };
  },
  methods: {
    MineFun(){
      if(localStorage.getItem('token')){
        this.$router.push({path:'/Mine'})
      }else{
        this.$router.push({path:'/Login'})
      }
    },
    toMail(){
      if(localStorage.getItem('token')){
        this.$router.push({path:'/Mail'})
      }else{
        this.$router.push({path:'/Login'})
      }
    },
    getyuyueList: function () {
      var t = this;
      clearInterval(t.intervalId);
      t.active = 2;
      this.$http({
        method: "get",
        url: "sys_yuyue_number"
      }).then((function (e) {
            t.yuyueList = e.data
            t.activeName = t.$t("text.txt12").replace("0", Math.floor(Math.random() * 2000) + 1000);
            for (var i = 0; i < t.yuyueList.length; i++) {
              t.yuyueList[i] = t.$t("text.txt13").replace("44", t.yuyueList[i]);
            }
            t.i = 0;
            t.intervalId = setInterval(function () {
              if(t.i >= t.yuyueList.length*48){
                t.i = 0;
              }
              t.i =  t.i + 1;

            }, 30);
          }
      ))
    },
    gotoMenu(router) {
      this.$router.replace(router)
    },

    profile(id) {
      this.$router.push({path: '/profile?id=' + id});
    },
    toLottery(key, id) {
      if (!localStorage.getItem('token')) {
        this.$router.push({path: '/Login'})
      } else {
        this.$router.push({path: '/Lottery?key=' + key + "&id=" + id})
      }

    },
    toPlayVideo(id) {
      if (!localStorage.getItem('token')) {
        this.$router.push({path: '/Login'})
      } else {
        this.$router.push({path: '/PlayVideo?id=' + id})
      }
    },
    onRefresh() {
      setTimeout(() => {
        this.getBasicConfig();
        this.isLoading = false;
        this.$toast(this.$t("msg.刷新成功！"));
      }, 500);
    },

    getMail() {
      this.$http({
        method: 'get',
        url: 'get_mail'
      }).then(res => {
        if(res.data){
          this.mailText = res.data;
          this.mailType = true;
        }
      })

    },

    getBasicConfig() {
      this.$http({
        method: 'get',
        url: 'sys_config'
      }).then(res => {
        this.basicData = res.data;
        this.getNotice(this.basicData);//获取公告
        this.getBanner(this.basicData);//获取banner
        //this.getGameItem();//获取首页游戏列表
        this.consort = this.basicData.consort;
      })

    },
    getNotice(data) {
      this.notice = data.notice;
    },
    getGameItem() {
      this.$http({
        method: 'get',
        url: 'lottery_hot'
      }).then(res => {
        this.gameitem = res.data
      })
    },
    getUserInfo(){
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res=>{
        if(res.code === 200){
          this.userInfo = res.data;
          this.menu_top = 15;
          this.mail = res.data.mail;
          if(this.userInfo.status !== 1){
            this.$toast(this.$t("msg.账号下线"));
            localStorage.clear()
            this.$router.push({path:'/Login'})
          }
        }else if(res.code ===401){
          //this.$toast(res.msg);
        }
      })
    },
    getBanner(data) {
      this.banners = data.banners;
    }
  },
  mounted() {

  },
  created() {
    this.getBasicConfig();
    this.getUserInfo();
    this.getMail();
  }
}

</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
.van-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background: #000000b3;
}
.banner_swiper {
  height: 100%;
  width: 94%;
  margin-top: auto;
  .van-image--round{
    border-radius: 10PX;
  }
  .swiper-slide {
    border-radius: 10PX;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
    font-weight: bold;
    font-size: 20PX;
    background-color: #ffffff;
    background-position: center;
    background-size: cover;
    color: #ffffff;
  }
}
::v-deep .swiper-container-3d .swiper-slide-shadow-left {
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
}
::v-deep .swiper-container-3d .swiper-slide-shadow-right {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
}
</style>
