<template>
  <div class="movie">

    <div class="van-nav-bar van-hairline--bottom">
      <div class="van-nav-bar__content">
        <div class="van-nav-bar__title van-ellipsis">
          {{ $t('video.title') }}
        </div>
        <div class="van-nav-bar__right van-haptics-feedback">
          <i class="van-badge__wrapper van-icon" style="font-size: 28PX;" @click="toMail()">
            <img src="/img/home/info.png" class="van-icon__image">
            <div class="van-badge van-badge--fixed" style="top:2PX;font-size: 3vw;padding: 0 1vw;" v-if ="mail > 0">{{mail > 100? 99 : mail}}</div>
          </i>
        </div>
      </div>
    </div>
    <van-tabs v-model="active" animated swipeable  @change="OnChange">
      <van-tab v-for="(v,key) in videolitem" :key="key" :title="v.name" :name="v.key" ></van-tab>
    </van-tabs>
    <swiper class="video_swiper" ref="swiper" :options="videoSwiperOption" @slideChange="itemChange">
      <swiper-slide v-for="(v,key) in videolitem" :key="key">
        <div class="movie-list-tab">
          <van-pull-refresh v-model="isLoading" @refresh="onRefresh"  :pulling-text="$t('msg.下拉即可刷新')"  :loosing-text="$t('msg.释放即可刷新')" :loading-text="$t('msg.加载中')">
            <div class="hot-recommend-div">
                <van-list
                    v-model="loading"
                    :finished="finished"
                    :immediate-check="false"
                    :loading-text="$t('msg.加载中')"
                    :finished-text="$t('msg.没有更多了')"
                    @load="onLoad"
                >
                  <div class="list-item">
                    <div class="movie-list-item" v-for="(v,key) in videolist" :key="key" @click="toPlayVideo(v.id)">
                      <van-image class="cover_img"  round :src="v.vod_pic">
                        <template v-slot:loading>
                          <van-loading type="circular"/>
                        </template>
                      </van-image>
                      <div class="movie-list-item-bottom">
                        <div class="movie-time-div">
                          <span>{{v.vod_name}}</span>
                          <span>{{ $t('video.play') }}:{{v.count}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </van-list>

            </div>
          </van-pull-refresh>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { Toast } from 'vant';
export default {
  data() {
    return {
      active: 0,
      isLoading: false,
      count:0,
      mail:0,
      loading: false,
      finished: false,
      refreshing: false,
      videolitem: [],
      videolist: [],
      number:0,
      page:0,
      videoSwiperOption: {
        slidesPerView: 'auto',
        spaceBetween: 0,
        slidesPerGroup : 1,
      }
    };
  },
  methods: {

    toMail(){
      if(localStorage.getItem('token')){
        this.$router.push({path:'/Mail'})
      }else{
        this.$router.push({path:'/Login'})
      }
    },
    getVideoClass(){
      this.$http({
        method: 'get',
        url: 'video_class'
      }).then(res=>{
        this.videolitem = res.data;
      })
    },
    toPlayVideo(id){
      if(!localStorage.getItem('token')){
        this.$router.push({path:'/Login'})
      }else {
        this.$router.push({path:'/PlayVideo?id='+id})
      }

    },
    itemChange(){
      this.active = this.$refs.swiper.swiper.activeIndex
      this.OnChange()
    },
    getVideoList(){
      this.$http({
        method: 'get',
        data:{id:this.active,page:this.page},
        url: 'video_list'
      }).then(res=>{
        this.videolist = this.videolist.concat(res.data.data);
        this.count = res.data.count;
        this.page++;

      })
    },
    onLoad() {
        this.getVideoList();
      let timer = setTimeout(() => {
        if (this.refreshing) {
          this.videolist = [];
          this.refreshing = false;
        }
        this.loading = false;
        if (this.videolist.length === this.count) {
          this.finished = true;
        }
        this.finished && clearTimeout(timer);//清除计时器
      }, 500);
    },
     OnChange(){
      this.videolist = [];
      this.number = 0;
      this.page = 0;
      this.count = 0;
      this.getVideoList();//获取视频列表

    },
    onRefresh() {
      setTimeout(() => {
        this.finished = false;
        this.loading = true;
        this.onLoad();
        this.isLoading = false;
        Toast(this.$t("msg.刷新成功！"));
      }, 500);
    },

    getUserInfo(){
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res=>{
        if(res.code === 200){
          this.userInfo = res.data;
          this.menu_top = 15;
          this.mail = res.data.mail;
          if(this.userInfo.status !== 1){
            this.$toast(this.$t("msg.账号下线"));
            localStorage.clear()
            this.$router.push({path:'/Login'})
          }
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
  },
  created() {
    this.getVideoClass();//获取视频类目
    this.OnChange();
    this.getUserInfo();
  }
};
</script>

<style lang='less' scoped>
.list-item{
  display: flex;
  width: calc(100% - 20PX);
  margin: 10PX auto;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.movie-list-item .cover_img{
  border-radius: 10PX;
  width:100%;
  height:200PX;
}
.movie-list-item{
  margin-bottom: -10PX;
}
.movie-list-item{
  width: calc(50% - 20PX);
  margin: auto;
}
.list-item .movie-list-item-bottom{
  position: relative;
  width: 100%;
  bottom: 42PX;
}
.list-item .movie-list-item-bottom .movie-time-div{
  background-color: rgba(0,0,0,.4);
}
.list-item .movie-list-item-bottom>div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.list-item .movie-list-item-bottom .movie-time-div .van-count-down {
  color: #fff;
}
.list-item .movie-list-item .movie-time-div span:first-child {
  overflow: hidden;
  white-space: nowrap;
  width: 70%;
  padding-left: 8PX;
  font-size: 16PX;
}
.list-item .movie-time-div {
  color: #fff;
  border-radius: 0 0 10PX 10PX;
  height: 35PX;
}

</style>
