const Spa = {
    text: {
        login_txt1: "※ no se puede usar a menores de 18 años",
        choose: "Coincidencia",
        bond: "Certificado y pagado un depósito de $10000",
        img: "El retrato indica",
        intention: "Lleno de energía",
        casual: "Instrucciones para el sexo casual",
        txt1: "Nota: esta plataforma ofrece los ganchos de transporte aéreo más auténticos. Para proteger la privacidad de los usuarios, la membresía requiere contactar con el servicio al cliente o la recomendación de una persona exitosa.",
        txt2: "Consultar el servicio al cliente",
        txt3: "Guía",
        txt4: "Anote el número de su hermana y Póngase en contacto con el servicio al cliente.",
        ok: "Vale",
        cancel: "Cancelación",
        txt5: "Para los miembros, solo se muestran el 1% de los recursos en el sitio web, y las actualizaciones son irregulares. Para recursos detallados, Póngase en contacto con el servicio al cliente.",
        txt6: "Toda la información de los miembros en la Plataforma es verdadera, segura y confiable. Se puede usar en todo el país o encontrar nuevos encuentros en la misma calle. Elige a la mujer que te guste y conoce en realidad. Participa ahora mismo.",
        txt7: "Información de los miembros",
        txt8: "Coincidencia",
        txt9: "Uso del Estatuto",
        txt10: "Quiero salir con ella.",
        txt11: "Esta plataforma ofrece la búsqueda de deflación más real. Para proteger la privacidad de la información personal, el registro solo puede registrarse contactando con el apoyo o aceptando recomendaciones de nombre real de personas que han buscado con éxito experiencia en deflación.",
        txt12: "Emparejamiento en línea actual 0",
        txt13: "44 activado",
        txt14: "Beneficios de los miembros",
        txt15: "Incidencias",
        txt16: "Video de membresía",
        txt17: "Engañarlo",
        txt18: "Información de los miembros",
        txt19: "Nuevo ID",
        txt20: "Encuentro",
        txt21: "Fin de la votación",
        txt22: "Resultados del período",
        txt23: "Período",
        txt24: "Número de selección",
        credit_score: "Puntuación crediticia",
        mail: "Carta de la estación",
        txt25: "Se necesitan 15 puntos porcentuales para mejorar",
        txt26: "Puntos individuales",
        txt27: "Puntos (- 8361;)",
        txt28: "Recarga de puntos",
        txt29: "Cambiar punto",
        txt30: "Servicios en línea",
        txt31: "Introducción",
        txt32: "Detalles del punto",
        txt33: "Intercambio de currículos",
        txt34: "Registro de datos",
        txt35: "Cambiar contraseña",
        txt36: "Cambiar punto",
    },
    login: {
        title: "Iniciar sesión",
        username: "Por favor ingrese el nombre de usuario",
        password: "Por favor ingrese la contraseña",
        forgetPaw: "Olvida la contraseña",
        registerNow: "Sin cuenta? Regístrese de inmediato",
        doLogin: "Iniciar sesión",

    },
    profile: {
        portrait: "Retrato",
        video: "Video",
        jianjie:" Perfil personal",
        liji: "Haga una cita ahora",
        chongdan: "Rango de carga",
        fd: "Mujer",
        yuyue: "Reserve una cita",

        archives: "Archivo de hermana",
        age: "Edad",
        height: "Altura",
        weight: "Peso",
        cup: "Copa cubierta",
        city: "Ciudad",
        area: "Área de servicio",
        item: "Artículos de servicio"
    },
    register:{
        title: "Registrarse",
        username: "Por favor ingrese el nombre de usuario (6-12 letras o números en inglés)",
        password: "Ingrese la contraseña de inicio de sesión (6-12 letras o números en inglés)",
        code: "Por favor ingrese el código de invitación",
        agreement: "Ya conozco y acepto los tratados del Acuerdo de apertura de cuentas",
        chooseAgreement: "Por favor, marque el acuerdo de cuenta a continuación!",
        doRegister: "Registrarse",

    },
    footer:{
        home : "Inicio",
        game: "Reserve una cita",
        video: "Video",
        mine: "El mío",

    },
    game:{
        title: "Sala de citas",
        all: "Todo",

    },
    choose:{
        title: "Concubina",
        city: "Ciudad Daquan",
        rule: "La red más auténtica de la plataforma + visitas de negocios + la pasión de la ciudad, para garantizar la privacidad personal de cada usuario, los clientes solo pueden unirse a través del contacto con la recepcionista o el nombre real de los miembros de la plataforma.",
        price: "Proceso de precios",
        resource: "¿Cuáles son los recursos?",
        character: "Red roja, modelo, azafata, modelo tierno, estudiantes universitarios, solo que no se puede pensar, no hay paz",
        scope: "¿Alcance del servicio?",
        location: "La ciudad libre sobre el cañón, cualquier ubicación en el aire del país, las ciudades locales de primer y segundo nivel, las ciudades de tercer nivel también pueden ponerse en contacto con los arreglos de reserva de la recepcionista.",

    },
    home:{
        recommend: "Tarea recomendada",
        more: "Ver más",
        popularity: "Clasificación popular",
        hot: "Recomendaciones populares",

    },
    lottery:{
        jieguo: "Resultados de votación",
        jiesu: "Fin",
        record: "Registro de tareas",
        rule: "Reglas de juego",
        prompt: "Consejos de juego",
        gameplay: "Elija un número de la forma opcional y valorable para apostar",
        explain: "Notas ganadoras",
        wprole: "La suma de los tres números de lotería 11 ~ 18 es grande; El valor total de 3 a 10 es pequeño;",
        example: "Ejemplo de apuestas",
        option: "Programa de apuestas: número de lotería: 123, que es pequeño y mediano",
        task: "Lista de tareas",
        money: "Saldo disponible",
        submit: "Enviado",
        selection: "Número de selección actual",
        perNote: "Cantidad por nota",
        inputMoney: "Por favor ingrese la cantidad",
        total: "Total",
        note: "Total",
        statistics: "Total",
        empty: "Vaciar la orden",
        verify: "Confirmar enviar",
        issue: "Número de período",
        num: "Número de lotería",
        big: "Grande",
        small: "Pequeño",
        single: "Solo",
        double: "Doble",
        unselected: "No seleccionado",
        drawLottery: "Éxito de la lotería, número de período:",
        kefu: "Por favor, póngase en contacto con el administrador de esta tarea!",

    },
    mine:{
        recharge: "Recarga",
        withdraw: "Levanta el dinero",
        myMoney: "Mi billetera",
        detail: "Detalles",
        balance: "Saldo",
        finance: "Informe personal",
        order: "Detalles de la cuenta",
        record: "Registro de tareas",
        my: "Centro personal",
        notice: "Anuncio de información",
        service: "Servicio al cliente en línea",
        score: "Integral",
    },
    setting:{
        title: "Establecer",
        basic: "Configuración de información básica",
        password: "Contraseña de inicio de sesión",
        capital: "Contraseña de fondos",
        lang: "Lenguaje",
        quit: "Salga del inicio de sesión",

    },
    setPayPassword: {
        title: "Establecer la contraseña de fondos",
        opw: "Por favor ingrese la contraseña",
        tpw: "Por favor ingrese la contraseña de nuevo",

    },
    gameRecord:{
        reject: "Rechazada",
        profit: "Ganancias",
        place: "Hacer un pedido",
        title: "Registro de tareas",
        amountBet: "Cantidad de apuestas",
        create: "Tiempo de pedido",
        update: "Tiempo de liquidación",

    },
    video:{
        title: "Cinema de video",
        play: "Jugar",

    },
    playVideo:{
        play: "Reproducción secundaria",

    },
    setname:{
        title: "Modificar el nombre real",
        name: "Nombre",
        truename: "Por favor ingrese el nombre real",
        hint: "Para la seguridad de su cuenta, el nombre real debe ser el mismo que el nombre de la tarjeta bancaria vinculada",

    },
    setsex:{
        title: "Cambio de género",

    },
    bindCard:{
        title: "Complete la tarjeta receptora",
        msg: "Por favor ingrese la información de su tarjeta receptora",
        hint: "Estimado usuario, para proteger su seguridad financiera, por favor vincule su nombre real y configure la contraseña de retiro, si el nombre es inconsistente con el nombre de la cuenta, no podrá retirar dinero",
        card: "Cuenta (normal)",
        truecard: "Ingrese la cuenta (normal)",
        name: "Nombre de la cuenta (seudónimo)",
        truename: "Ingrese el nombre de la cuenta (seudónimo)",
        financial_institution: "Nombre de la institución financiera",
        truefinancial_institution: "Ingrese el nombre de la institución financiera",
        branch: "Nombre de la sucursal",
        truebranch: "Ingrese el nombre de la sucursal",
        branch_number: "Número de sucursal",
        truebranch_number: "Ingrese el número de rama"
    },
    setbank:{
        title: "Información de la tarjeta receptora",
        bank: "Agregar tarjeta bancaria",
        hint: "Consejo: Por favor, vincule grandes bancos comerciales, si necesita cambios, póngase en contacto con el servicio al cliente en línea",

    },
    setLoginPassword:{
        title: "Modificar la contraseña de inicio de sesión",
        old_password: "Contraseña antigua",
        old_password_p: "Por favor ingrese la contraseña anterior",
        o_new_password: "Nueva contraseña",
        o_new_password_p: "Por favor ingrese una nueva contraseña",
        t_new_password_p: "Por favor ingrese la nueva contraseña nuevamente",

    },
    infomation:{
        title: "Información básica",
        head: "Avatar",
        select: "Elige un avatar",
        name: "Nombre real",
        sex: "Género",
        man: "Hombre",
        girl: "Mujer",
        unknown: "Desconocido",
        binding: "Información de enlace",

    },
    withdraw: {
        title: "Centro de recogida",
        record: "Registros de pago",
        money: "Cantidad de efectivo",
        all: "Todo",
        min: "Límite único: mínimo",
        max: "",
        num: "Reclamo: un día efectivo",
        hint: "Tiempo de llegada: el tiempo de llegada general es de aproximadamente 5 minutos, el más rápido dentro de los 2 minutos de la cuenta",
        quota: "Descripción del límite",
        balance: "Saldo",
        withdraw: "Levanta de inmediato",

    },
    withdrawRecord:{
        desc: "Descripción",
        create: "Tiempo de envío",
        update: "Tiempo de auditoría",

    },
    personalreport:{
        title: "Informe personal",
        profit: "Cantidad de ganancia",
        formula: "Fórmula de cálculo de ganancias: cantidad ganadora-monto de la tarea",
        task: "Cantidad de la tarea",
        recharge: "Cantidad de recarga",
        withdrawal: "Cantidad de efectivo",
        win_money: "Cantidad ganadora",

    },
    service: {
        title: "Servicio al cliente en línea",
        relation: "Contacto",
        hint: "7 * 24 horas al día para su servicio dedicado",

    },
    msg:{
        "次": "Tiempos",
        "用户不存在！": "¡El usuario no existe!",
        "密码错误！": "¡Error de contraseña!",
        "登录成功！": "¡Inicia sesión con éxito!",
        "邀请码不存在！": "¡El código de invitación no existe!",
        "用户名位数错误！": "Error de nombre de usuario!",
        "密码位数错误！": "¡Error de contraseña!",
        "用户名不能存在中文！": "¡El nombre de usuario no puede existir en chino!",
        "邀请码不能为空！": "¡El código de invitación no puede estar vacío!",
        "注册成功！": "Registro exitoso!",
        "注册失败！": "Error de registro!",
        "用户已存在": "El usuario ya existe",
        "刷新成功！": "¡Actualización exitosa!",
        "请联系顾问或接待员": "Por favor, póngase en contacto con un consultor o recepcionista",
        "金额错误！": "¡La cantidad es incorrecta!",
        "请选号！": "Por favor marque!",
        "请填写金额!": "Por favor complete la cantidad!",
        "余额不足，请联系客服充值！": "El saldo no es suficiente, ¡contáctese con la recarga del servicio al cliente!",
        "没有更多了": "No más",
        "没有数据": "Sin datos",
        "余额不足！": "¡Balance insuficiente!",
        "扣款失败！": "¡La deducción falló!",
        "下注异常！": "¡Apuesta anormal!",
        "投注成功！": "¡Apuesta al éxito!",
        "参数异常！": "Parámetros anormales!",
        "获取中": "Obtener...",
        "请完成任务单后进入": "Por favor, complete la lista de tareas",
        "请联系客服充值": "Por favor, póngase en contacto con la recarga del servicio al cliente",
        "请设置收款卡!": "Por favor, configure la tarjeta receptora!",
        "功能已禁用!": "¡La función está deshabilitada!",
        "账号下线": "Cuenta fuera de línea",
        "登录/注册": "Iniciar sesión/registrarse",
        "登录可享受更多服务！": "¡Inicie sesión para disfrutar de más servicios!",
        "未设置": "No establecido",
        "已设置": "Establecido",
        "提交": "Enviado",
        "确定": "OK",
        "请填写完整": "Por favor complete",
        "两次密码不一致！": "¡Dos contraseñas inconsistentes!",
        "设置提现密码成功！": "¡Establezca la contraseña correcta!",
        "提现密码已设置，需要修改请联系客服": "La contraseña se ha establecido, debe modificar el servicio al cliente de contacto",
        "已绑定": "Vinculado",
        "无": "Ninguno",
        "更换头像成功！": "T cambia el éxito del avatar!",
        "更新头像失败！": "Actualizar avatar fallido!",
        "请勿重复设置！": "No repita la configuración!",
        "设置姓名成功！": "Establecer el nombre es exitoso!",
        "设置姓名失败！": "Establecer el nombre falló!",
        "设置性别成功！": "Establecer el éxito de género!",
        "设置性别失败！": "Establecer falla de género!",
        "请设置姓名后再绑定银行卡！": "Por favor, configure el nombre antes de vincular la tarjeta bancaria!",
        "请设置提现密码后再绑定银行卡！": "Por favor, configure la contraseña y luego enlace la tarjeta bancaria.",
        "确认绑卡": "Confirmar la tarjeta de enlace",
        "请输入银行卡号！": "Por favor ingrese al banco!",
        "请输入银行名称！": "Por favor ingrese el nombre del banco!",
        "设置银行卡成功！": "Establecer el éxito de la tarjeta bancaria!",
        "设置银行卡失败！": "¡La configuración de la tarjeta bancaria falló!",
        "旧密码错误": "Error de contraseña anterior",
        "修改密码成功！": "¡Cambia la contraseña con éxito!",
        "修改密码失败！": "¡Error al cambiar la contraseña!",
        "请填写正确的金额": "Por favor complete la cantidad correcta",
        "提现金额不能少于：": "La cantidad de efectivo no puede ser menor que:",
        "提现金额不能大于：": "La cantidad de efectivo no puede ser mayor que:",
        "当日提现次数已用完": "El día se ha agotado",
        "提现成功！": "Levanta el éxito!",
        "提现失败！": "¡Reclamo fallido!",
        "金额": "Cantidad",
        "待审核": "Para ser revisado",
        "审核成功": "Examen exitoso",
        "审核退回": "Auditoría devuelta",
        "请充值后观看视频！": "Por favor, recargue para ver el video!",
        "释放即可刷新": "Libera para actualizar...",
        "加载中": "Cargando...",
        "下拉即可刷新": "Tire hacia abajo para actualizar...",
    },
    setLang:{
        title: "Establecer el idioma"
    },
    lang:{
        zh: "Chino",
        jp: "Japón",
        ara: "Lenguaje",
        en: "Inglés",
        spa: "Español",
        it: "Italiano",
        de: "Alemán",
        pt: "Portugués",
        fra: "Francés",
        nl: "Holanda",
        id: "Indonesio",
        hi: "Hindi",
        kor: "Coreano",
        tr: "Turco",
        swe: "Sueco",
        ga: "Irlandés",
        ru: "Ruso",
        vie: "Vietnamita",
        th: "Thai",
        est: "Estonio",
        be: "Cerebro bielorruso",
        bul: "Búlgaro",
        pl: "Polaco",
        ms: "Malayo",
    }
}
export default Spa