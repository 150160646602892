const It = {
    text: {
        login_txt1: "I minori di 18 anni non possono utilizzarlo",
        choose: "corrispondenza",
        bond: "Verificato e pagato un deposito di $ 10000",
        img: "Rappresentazione verticale",
        intention: "Tolleranza del piede",
        casual: "Istruzioni per il sesso casuale",
        txt1: "Nota: Questa piattaforma fornisce i prodotti più autentici del gancio e del loop del trasporto aereo. Per proteggere la privacy degli utenti, l'iscrizione richiede di contattare il servizio clienti o raccomandato da individui di successo.",
        txt2: "Consulenza al servizio clienti",
        txt3: "guida",
        txt4: "Scrivi il numero di tua sorella e contatta il servizio clienti",
        ok: "OK",
        cancel: "annulla",
        txt5: "A beneficio di tutti i membri, solo l'1% delle risorse viene visualizzato sul sito e gli aggiornamenti sono irregolari. Per informazioni dettagliate, contattare il servizio clienti.",
        txt6: "Tutte le informazioni sui membri sulla piattaforma sono autentiche, sicure e affidabili. Può essere utilizzato a livello nazionale, e nuovi incontri possono essere trovati anche sulla stessa strada. Scegli una donna che ti piace e incontrarla di persona. Unisciti ora~",
        txt7: "Informazioni sui membri",
        txt8: "corrispondenza",
        txt9: "Usa il protocollo",
        txt10: "Voglio uscire con lei.",
        txt11: "Questa piattaforma fornisce la ricerca più autentica della deflazione. Per proteggere la privacy delle informazioni personali, la registrazione può essere effettuata solo contattando il supporto o accettando raccomandazioni di nome reale da parte di individui che hanno cercato con successo l'esperienza di deflazione.",
        txt12: "Attualmente 0 accoppiamenti online",
        txt13: "44 attivazione",
        txt14: "Prestazioni per i membri",
        txt15: "evento",
        txt16: "Video membro",
        txt17: "Mentigli",
        txt18: "Informazioni sui membri",
        txt19: "Nuovo ID",
        txt20: "incontro",
        txt21: "La votazione termina",
        txt22: "Risultati del periodo",
        txt23: "periodo",
        txt24: "Seleziona casualmente il numero",
        credit_score: "Punteggio di credito",
        mail: "Lettera stazione",
        txt25: "L'aggiornamento richiede 15 punti percentuali",
        txt26: "Punti personali",
        txt27: "Punto ( 8361;)",
        txt28: "Ricarica punti",
        txt29: "Cambia la posizione",
        txt30: "servizio online",
        txt31: "breve introduzione",
        txt32: "Clicca su informazioni dettagliate",
        txt33: "Riavvia lo scambio",
        txt34: "record di dati",
        txt35: "Cambia password",
        txt36: "Cambia la posizione",
    },
    login: {
        title: "Log on", //登录
        username:"Digitate il nome dell’utente",//请输入用户名
        password:"Digitare la parola d’ordine",//请输入密码
        forgetPaw:"Dimenticate le password",//忘记密码
        registerNow:"Nessun conto? Registrazione immediata",//没有账户？马上注册
        doLogin:"Log on",//登录

    },
    register:{
        title: "registrazione", //注册
        username:"Indicare il nome dell’utilizzatore (6-12 lettere o cifre)",//请输入用户名(6-12个英文字母或数字)
        password:"Digitare la parola d’accesso (6-12 lettere o cifre).",//请输入登录密码(6-12个英文字母或数字)
        code:"Digitare il codice di invito",//请输入邀请码
        agreement:"Conosco già i trattati e li condivido",//我已经知晓并同意"开户协议"各项条约
        chooseAgreement:"Spunta un accordo di apertura di un conto!",//请勾选下方开户协议！
        doRegister:"registrazione",//注册
    },
    footer:{
        home :"pagina",//首页
        game:"appuntamento",//预约
        video:"video",//视频
        mine:"La mia",//我的
    },
    game:{
        title:"Locali riservati",//预约大厅
        all:"totale",//全部
    },
    choose:{
        title:"XuanFei",//选妃
        city:"Città grande",//城市大全
        rule:"Per garantire la privacy personale di ogni utente, i clienti possono aderire solo contattando l’addetto al ricevimento o fornendo il nome completo del membro più anziano della piattaforma.",//本平台全网最真实外围+商务伴游+同城激情，为保证每个用户的个人隐私，客户仅限通过联系接待员或平台资深会员实名推荐才可加入。
        price:"Evoluzione dei prezzi",//价格流程
        resource:"Quali sono le risorse disponibili?",//有哪些资源?
        character:"Cyber red, modelle, hostess di volo, unmh, studenti universitari, non potete immaginarvelo. Non potete farlo senza questa piattaforma",//网红、模特、空姐、嫩模、大学生，只有您想不到，没有本平台办不到
        scope:"Gamma dei servizi?",//服务范围?
        location:"Le città gemellate sono libere di volare in qualsiasi punto del paese, sono presenti nella zona urbana domestica di primo e secondo livello e le città di terzo livello possono anche prendere appuntamento con gli addetti al ricevimento.",//同城免费约炮，任意地点全国空降，国内一二线城市当地均有，三线城市也可通过联系接待员预约安排。
    },
    home:{
        recommend:"Missione raccomandata",//推荐任务
        more:"Per saperne di più",//查看更多
        popularity:"Buona compagnia",//人气排行
        hot:"Raccomandazione di conclusione",//热门推荐
    },
    lottery:{
        jieguo: "Risultato della votazione",
        jiesu: "& Fine",
        record:"Registrazione delle attività",//任务记录
        rule:"Le regole del gioco",//玩法规则
        prompt:"Metodi di calcolo",//玩法提示
        gameplay:"Scegliere i numeri all’interno della formula facoltativa e di valore per le scommesse",//从可选和值形态里面选择号码进行下注
        explain:"Descrizione delle vincite",//中奖说明
        wprole:"La somma dei tre numeri iniziali, da 11 a 18, è grande; I valori della somma da 3 a 10 sono piccoli;",//三个开奖号码总和值11~18 为大;总和值3~ 10为小;
        example:"Esempi di rischi",//投注范例
        option:"Sistema di partecipazione: numero iniziale: 123, cioè piccole e medie imprese",//投注方案：小 开奖号码：123,即中小
        task:"Elenco dei compiti",//任务单
        money:"Saldo disponibile",//可用余额
        submit:"presentazione",//提交
        selection:"Attuali numeri di catalogo",//当前选号
        perNote:"Importo per nota",//每注金额
        inputMoney:"Indicare l’importo",//请输入金额
        total:"totale",//总共
        note:"totale",//总共
        statistics:"totale",//合计
        empty:"Ordine di cancellazione",//清空订单
        verify:"Conferma di trasmissione",//确认提交
        issue:"Periodo di n.",//期号
        num:"Numero di lancio",//开奖号码
        big:"grandi",//大
        small:"piccolo",//小
        single:"mono",//单
        double:"doppio",//双
        unselected:"Nessuna selezione",//未选择
        drawLottery:"Il premio è stato assegnato a:",//开奖成功，期号：
        kefu:"Si prega di contattare l’amministratore per avere il compito!",//请联系管理员领取该任务!
    },
    mine:{
        recharge:"complementari",//充值
        withdraw:"ritiro",//提现
        myMoney:"Il mio portafoglio",//我的钱包
        detail:"dettagli",//详情
        balance:"saldo",//余额
        finance:"Scheda individuale",//个人报表
        order:"Particolari della contabilità",//账户明细
        record:"Registrazione delle attività",//任务记录
        my:"Centri personali",//个人中心
        notice:"Bollettino d’informazione",//信息公告
        service:"Servizio clienti online",//在线客服
        score: "I punti",
    },
    setting:{
        title:"regolazione",//设置
        basic:"Elaborazione delle informazioni di base",//基本信息设置
        password:"Parola d’accesso",//登录密码
        capital:"Codici di finanziamento",//资金密码
        lang:"lingua",//语言
        quit:"Uscita logon",//退出登录
    },
    setPayPassword: {
        title:"Stabilire il codice dei finanziamenti",//设置资金密码
        opw:"Per favore digitate il codice del finanziamento",//请输入资金密码
        tpw:"Per favore, digitate di nuovo il codice del finanziamento",//请再次输入资金密码
    },
    gameRecord:{
        reject: "respinge",
        profit: "profitto",
        place: "effettuare un ordine",
        title:"Registrazione delle attività",//任务记录
        amountBet:"Importo delle entrate",//下注金额
        create:"La prossima ora",//下单时间
        update:"Momento della liquidazione",//结算时间
    },
    video:{
        title:"Il video cinema",//视频影院
        play:"In onda",//播放
    },
    playVideo:{
        play: "sottodiffusione",//次播放
    },
    setname:{
        title:"Modifica dei nomi autentici",//修改真实姓名
        name:"nomi",//姓名
        truename:"Per piacere digitare il nome esatto",//请输入真实姓名
        hint:"Per garantire la sicurezza del proprio conto, il nome autentico deve corrispondere a quello della carta di credito consolidata",//为了您账户安全,真实姓名需要与绑定银行卡姓名一致
    },
    setsex:{
        title:"Modifica del sesso",//性别修改
    },
    bindCard:{
        title:"Compilare la carta di raccolta",//填写收款卡
        msg:"Digitate le informazioni sulla vostra carta di credito",//请输入您的收款卡信息
        hint:"Onorevoli deputati, per garantire la sicurezza del vostro denaro, vi invitiamo a rivedere il vostro vero nome e a fissare una password per il prelievo, che non sarà ottenibile se il nome non coincide con il nome di apertura di un conto",//尊敬的用户,为了保障您的资金安全,请您绑定您的真实姓名和设置取款密码,如果姓名与开户名不一致,将无法取款
        card: "Numero di conto (ordinario)",
        truecard: "Inserisci il numero di conto (normale)",
        name: "Nome dell'account (vero nome)",
        truename: "Inserisci il nome dell'account (vero nome)",
        financial_institution: "Nome dell'Istituzione Finanziaria",
        truefinancial_institution: "Inserisci il nome dell'istituto finanziario",
        branch: "Nome della filiale",
        truebranch: "Inserisci il nome della filiale",
        branch_number: "Numero di filiale",
        truebranch_number: "Inserisci il numero di riga"
    },
    setbank:{
        title:"Informazioni sulle carte di credito",//收款卡信息
        bank:"Aggiunta di carte bancarie",//添加银行卡
        hint:"Nb: leggete le grandi banche commerciali e, in caso di modifiche, contattate il servizio clienti online",//提示:请绑定大型商业银行,如需修改,请您联系在线客服
    },
    setLoginPassword:{
        title:"Modificare la password logon",//修改登录密码
        old_password:"Codice vecchio",//旧密码
        old_password_p:"Per favore digitate la vecchia password",//请输入旧密码
        o_new_password:"Nuove password",//新密码
        o_new_password_p:"Per favore digitate la nuova password",//请输入新密码
        t_new_password_p:"Per favore, digitate di nuovo la password",//请再次输入新密码
    },
    infomation:{
        title:"Informazioni di base",//基本信息
        head:"avatar",//头像
        select:"Scegliere il profilo",//选择头像
        name:"Nome autentico",//真实姓名
        sex:"sesso",//性别
        man:"uomo",//男
        girl:"donna",//女
        unknown:"sconosciuto",//未知
        binding:"Informazioni vincolanti",//绑定信息
    },
    withdraw: {
        title:"Centri di ritiro",//提现中心
        record:"Registrazione del ritiro",//提现记录
        money:"Importi ritirati",//提现金额
        all:"totale",//全部
        min:"Limite unico: minimo",//单笔限额：最低
        max:"massimo",//最高
        num:"Numero massimo di ritiri: massimo al giorno",//提现次数：一天最高提现
        hint:"Per quanto riguarda le scadenze di pagamento, esse sono generalmente di circa 5 minuti e sono più brevi di 2 minuti",//到账时间：一般到账时间在5分钟左右，最快2分钟内到账
        quota:"Descrizione dei limiti",//限额说明
        balance:"saldo",//余额
        withdraw:"Ritiro immediato",//马上提现
    },
    withdrawRecord:{
        desc:"descrizione",//说明
        create:"Tempo di presentazione",//提交时间
        update:"Momento della revisione",//审核时间
    },
    personalreport:{
        title:"Scheda individuale",//个人报表
        profit:"Importo utile",//盈利金额
        formula:"Formula di calcolo della redditività: importo del premio — importo della missione",//盈利计算公式 : 中奖金额 - 任务金额
        task:"Importo della missione",//任务金额
        recharge:"Importo compensativo",//充值金额
        withdrawal:"Importi ritirati",//提现金额
        win_money:"Importo della sovvenzione",//中奖金额
    },
    service: {
        title:"Servizio clienti online",//在线客服
        relation:"contatti",//联系
        hint:"24 ore e mezzo",//全天7 * 24小时竭诚为您服务
    },
    msg:{
        "次":"tempi",
        "用户不存在！":"Gli utenti non esistono!",//用户不存在！
        "密码错误！":"Errore di password!",
        "登录成功！":"Un successo!",
        "邀请码不存在！":"I codici di invito non esistono!",
        "用户名位数错误！":"Errore di nome utente!",
        "密码位数错误！":"Errore di numero d’ordine!",
        "用户名不能存在中文！":"Il nome utente non può esistere in cinese!",
        "邀请码不能为空！":"Il codice di invito non deve restare vuoto!",
        "注册成功！":"Registrazione: successo!",
        "注册失败！":"Mancata registrazione!",
        "用户已存在":"Gli utenti già esistenti",
        "刷新成功！":"Lavatevi un nuovo successo!",
        "请联系顾问或接待员":"Si prega di contattare un consulente o una reception",
        "金额错误！":"Gli importi sono sbagliati!",
        "请选号！":"Indicare il numero",
        "请填写金额!":"Indicare l’importo!",
        "余额不足，请联系客服充值！":"Saldo insufficiente, per favore contattate il servizio clienti!",
        "没有更多了":"Niente di più",
        "没有数据":"Dati non disponibili",
        "余额不足！":"Saldo insufficiente!",
        "扣款失败！":"Non si pagano!",
        "下注异常！":"Scommettere!",
        "投注成功！":"La scommessa ha successo!",
        "参数异常！":"Anomalie di parametri!",
        "获取中":"Accesso …",
        "请完成任务单后进入":"Per piacere entri dopo aver terminato il suo ordine di missione",
        "请联系客服充值":"Si prega di contattare il servizio assistenza clienti",
        "请设置收款卡!":"Per favore, create una carta di raccolta!",
        "功能已禁用!":"La funzione non funziona più!",
        "账号下线":"I numeri dei conti scendono",
        "登录/注册":"Logon/registrazione",
        "登录可享受更多服务！":"Più servizi per collegarsi!",
        "未设置":"Non stabilito",
        "已设置":"Già stabilito",
        "提交":"presentazione",
        "确定":"definire",
        "请填写完整":"Vogliate compilare l’elenco completo",
        "两次密码不一致！":"Due volte la password non è coerente!",
        "设置提现密码成功！":"Fatevi avanti con il password proposto!",
        "提现密码已设置，需要修改请联系客服":"Il password di partenza è stato stabilito e richiede una modifica del vostro abbigliamento",
        "已绑定":"Impegni sottoscritti",
        "无":"senza",
        "更换头像成功！":"Sostituire le teste ha successo!",
        "更新头像失败！":"Aggiornare l’anteprima è un fallimento!",
        "请勿重复设置！":"Non ripetere!",
        "设置姓名成功！":"Il nome è un successo!",
        "设置姓名失败！":"Non è riuscito a definire il nome!",
        "设置性别成功！":"Un successo sul genere!",
        "设置性别失败！":"Un fallimento di genere!",
        "请设置姓名后再绑定银行卡！":"Per favore, leggete la carta bancaria dopo il vostro nome!",
        "请设置提现密码后再绑定银行卡！":"Per favore, decidete la password e leggete la carta bancaria dopo averla ritirata!",
        "确认绑卡":"Riconoscimento della carta di collegamento",
        "请输入银行卡号！":"Per favore scrivi il numero della carta bancaria!",
        "请输入银行名称！":"Per favore scrivi il nome della banca!",
        "设置银行卡成功！":"Il successo della carta bancaria!",
        "设置银行卡失败！":"Il fallimento della carta bancaria!",
        "旧密码错误":"Vecchi errori di password",
        "修改密码成功！":"Cambiare password: successo!",
        "修改密码失败！":"Mancata revisione della password!",
        "请填写正确的金额":"Per favore scrivi l’importo giusto",
        "提现金额不能少于：":"L’importo del ritiro non può essere inferiore a:",
        "提现金额不能大于：":"L’importo del ritiro non può essere superiore a:",
        "当日提现次数已用完":"Il numero di ritiri in giornata è stato esaurito",
        "提现成功！":"Il ritiro è un successo!",
        "提现失败！":"Il ritiro è fallito!",
        "金额":"l’importo",
        "待审核":"Da esaminare",
        "审核成功":"Il successo dell’audit",
        "审核退回":"Esame della restituzione",
        "请充值后观看视频！":"Per favore, basta guardare il video!",
        "释放即可刷新":"Il rilascio può essere modificato …",
        "加载中":"Carico …",
        "下拉即可刷新": "Pulsare per aggiornare...",
    },
    setLang:{
        title:"Stabilire le lingue"
    },
    profile: {
        portrait: "Fotografia",
        video: "Video",
        jianjie: "Profilo personale",
        liji: "Prenota ora",
        chongdan: "Intervallo di ricarica",
        fd: "Signora.",
        yuyue: "Appuntamento",
        archives: "Archivio della sorella",
        age: "Età",
        height: "Altezza",
        weight: "Peso corporeo",
        cup: "La coppa di copertura",
        city: "La città",
        area: "Aree di servizio",
        item: "Articoli di servizio"
    },
    lang:{
        "zh": "Cinese",
        "jp": "Giappone",
        "ara": "Lingua:",
        "en": "Italiano",
        "spa": "Spagnolo",
        "it": "Italiano-Italiano",
        "de": "Tedesco",
        "pt": "La lingua portoghese",
        "fra": "In francese",
        "nl": "La lingua olandese",
        "id": "Indonesiano",
        "hi": "La lingua hindi",
        "kor": "Coreano",
        "tr": "La lingua turca",
        "swe": "La lingua svedese",
        "ga": "Lingua irlandese",
        "ru": "Russo",
        "vie": "La lingua vietnamita",
        th: "La lingua tailandese",
        est: "La lingua estone",
        be: "La lingua bielorussa",
        bul: "La lingua bulgara",
        pl: "La lingua polacca",
        ms: "La lingua malese",
    }
}
export default  It