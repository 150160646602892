
<template>
  <div class="container page">
    <van-nav-bar :title="$t('setLang.title')" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()"/>
      </template>
    </van-nav-bar>
    <div class="sex">
      <van-radio-group v-model="radio">
        <div v-for="(item,keys) in list" :key="keys" class="item van-hairline--bottom" @click="chooesSex(keys)">
          <van-radio :name="keys">{{ item }}</van-radio>
        </div>
      </van-radio-group>

    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      radio:"",

      list:{
        "zh": "中文 ",
        "jp": "にほんご",
        "ara": "بالعربية ",
        "en": "English ",
        "spa": "Español ",
        "it": "Italiano ",
        "de": "Deutsch ",
        "pt": "Português ",
        "fra": "Français",
        "nl": "Nederlands",
        "id": "Indonesia",
        "hi": "हिंदी भाषा",
        "kor": "한국어",
        "tr": "Türkçe",
        "swe": "Svenska språket",
        "ga": "Íris",
        "ru": "Русский язык",
        "vie": "Tiếng Việt",
        "th": "ภาษาไทย",
        est: "Eesti keel",
        be: "Беларуская мова",
        bul: "Български",
        pl: "Język polski",
        ms: "Bahasa Malay",
      }
    };
  },
  methods: {
    back(){
      return window.history.back();
    },
    chooesSex(lang){
      localStorage.setItem('lang',lang);
      this.back();
    },
  },
  created() {

    this.radio = localStorage.getItem('lang');
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
.container .sex{
  background-color: #fff;
  padding: 0 20PX;
}
.container .sex .item{
  font-size: 18PX;
  line-height: 25PX;
  padding: 15PX 0;
}
::v-deep .van-radio__label {
  line-height: 25PX;
  margin-left: 15PX;
}
::v-deep .van-radio__icon {
  font-size: 16PX;
}
::v-deep .van-radio__icon--checked .van-icon {
  color: #fff;
  border-color: rgb(126, 86, 120);
  background-color: rgb(126, 86, 120);
}
.container .van-hairline--bottom::after {
  border-bottom-width: 3PX;
}
</style>
