const Swe = {
    text: {
        login_txt1: "Personer under 18 år får inte använda den",
        choose: "matchning",
        bond: "Verifierad och betalad en insättning på $ 10000",
        img: "Porträtt representation",
        intention: "Fotstolerance",
        casual: "Instruktioner för tillfällig sex",
        txt1: "Obs! Denna plattform erbjuder de mest autentiska krok och loop-produkterna för flygfrakt. För att skydda användarnas integritet krävs medlemskap att kontakta kundtjänst eller rekommenderas av framgångsrika individer.",
        txt2: "Konsultera kundtjänst",
        txt3: "guide",
        txt4: "Skriv ner din systers nummer och kontakta kundtjänst",
        ok: "OK",
        cancel: "avbryt",
        txt5: "Till förmån för alla medlemmar visas endast 1% av resurserna på webbplatsen och uppdateringar är oregelbundna. För detaljerade resurser, vänligen kontakta kundtjänst.",
        txt6: "All medlemsinformation på plattformen är äkta, säker och pålitlig. Den kan användas över hela landet, och nya möten kan också hittas på samma gata. Välj en kvinna du gillar och träffa henne personligen. Gå med nu~",
        txt7: "Medlemsinformation",
        txt8: "matchning",
        txt9: "Använd protokollet",
        txt10: "Jag vill gå på dejt med henne.",
        txt11: "Denna plattform ger den mest autentiska sökandet efter deflation. För att skydda integriteten för personuppgifter kan registrering endast göras genom att kontakta support eller acceptera riktiga namnrekommendationer från individer som framgångsrikt har sökt efter deflation erfarenhet.",
        txt12: "För närvarande 0 parkoppling online",
        txt13: "44 aktivering",
        txt14: "Medlemsförmåner",
        txt15: "händelse",
        txt16: "Medlemsvideo",
        txt17: "Ljug för honom",
        txt18: "Medlemsinformation",
        txt19: "Nytt ID",
        txt20: "träffas",
        txt21: "Omröstningen avslutas",
        txt22: "Resultat under perioden",
        txt23: "period",
        txt24: "Slumpmässigt välj numret",
        credit_score: "Kreditpoäng",
        mail: "Stationsbrev",
        txt25: "Uppgradering kräver 15 procentenheter",
        txt26: "Personliga punkter",
        txt27: "Punkt (8361;)",
        txt28: "Ladda poäng",
        txt29: "Ändra plats",
        txt30: "Internettjänst",
        txt31: "kort inledning",
        txt32: "Klicka på detaljerad information",
        txt33: "Bytet återupptas",
        txt34: "dataregister",
        txt35: "Ändra lösenord",
        txt36: "Ändra plats",
    },
    login: {
        title: "logga in", //登录
        username:"Ange ett användarnamn",//请输入用户名
        password:"Ange ett lösenord",//请输入密码
        forgetPaw:"Glömt lösenord",//忘记密码
        registerNow:"Har du inget konto? Registrera dig nu",//没有账户？马上注册
        doLogin:"logga in",//登录

    },
    register:{
        title: "skriva in sig", //注册
        username:"Ange användarnamn (6-12 alfanumeriska bokstäver)",//请输入用户名(6-12个英文字母或数字)
        password:"Ange ditt inloggningslösenord (6-12 alfanumeriska tecken)",//请输入登录密码(6-12个英文字母或数字)
        code:"Ange inbjudningskoden",//请输入邀请码
        agreement:"Jag är medveten om och godkänner villkoren i kontoöppningsavtalet",//我已经知晓并同意"开户协议"各项条约
        chooseAgreement:"Vänligen kryssa i kontoöppningsavtalet nedan!",//请勾选下方开户协议！
        doRegister:"skriva in sig",//注册
    },
    footer:{
        home :"Hem",//首页
        game:"bokning",//预约
        video:"Video",//视频
        mine:"Den är min",//我的
    },
    game:{
        title:"Bokning hall",//预约大厅
        all:"alla",//全部
    },
    choose:{
        title:"Välj en konkubin",//选妃
        city:"Den kompletta boken om städer",//城市大全
        rule:"För att säkerställa varje användares personliga integritet kan kunderna endast gå med genom att kontakta receptionisten eller rekommendationen med riktiga namn från ledande medlemmar på plattformen.",//本平台全网最真实外围+商务伴游+同城激情，为保证每个用户的个人隐私，客户仅限通过联系接待员或平台资深会员实名推荐才可加入。
        price:"Prisprocess",//价格流程
        resource:"Vilka resurser finns tillgängliga?",//有哪些资源?
        character:"Internetkändisar, modeller, flygvärdinnor, unga modeller, studenter, bara du kan inte tänka på det, och du kan inte göra det utan denna plattform",//网红、模特、空姐、嫩模、大学生，只有您想不到，没有本平台办不到
        scope:"Vad är tjänstens omfattning?",//服务范围?
        location:"Gratis anslutning i samma stad, luftburen var som helst över hela landet, lokal i första och andra klassens städer i Kina, och tredje nivå städer kan också boka tid genom att kontakta receptionisten.",//同城免费约炮，任意地点全国空降，国内一二线城市当地均有，三线城市也可通过联系接待员预约安排。
    },
    home:{
        recommend:"Rekommenderade uppgifter",//推荐任务
        more:"Visa mer",//查看更多
        popularity:"Popularitet ranking",//人气排行
        hot:"De bästa rekommendationerna",//热门推荐
    },
    lottery:{
        jieguo: "Röstresultat",
        jiesu: "Avsluta",
        record:"Registrering av uppgifter",//任务记录
        rule:"Spelregler",//玩法规则
        prompt:"Speltips",//玩法提示
        gameplay:"Välj nummer från val- och värdemönstren för att placera ditt spel",//从可选和值形态里面选择号码进行下注
        explain:"Vinnande instruktioner",//中奖说明
        wprole:"Summan av de tre lotterinumren är 11 ~ 18 är stor; Summan av 3 ~ 10 är liten;",//三个开奖号码总和值11~18 为大;总和值3~ 10为小;
        example:"Exempel på vadslagning",//投注范例
        option:"Spelschema: liten Dragningsnummer: 123, det vill säga medelstora och små",//投注方案：小 开奖号码：123,即中小
        task:"Biljett",//任务单
        money:"Tillgängligt saldo",//可用余额
        submit:"inlämna",//提交
        selection:"Det aktuella urvalsnumret",//当前选号
        perNote:"Belopp per insats",//每注金额
        inputMoney:"Ange beloppet",//请输入金额
        total:"Total",//总共
        note:"Total",//总共
        statistics:"Total",//合计
        empty:"Töm ordern",//清空订单
        verify:"Bekräfta inlämningen",//确认提交
        issue:"Nummer",//期号
        num:"Rita nummer",//开奖号码
        big:"stor",//大
        small:"liten",//小
        single:"singel",//单
        double:"par",//双
        unselected:"Inte valt",//未选择
        drawLottery:"Dragningen lyckades, periodnummer:",//开奖成功，期号：
        kefu:"Kontakta administratören för att göra anspråk på uppgiften!",//请联系管理员领取该任务!
    },
    mine:{
        recharge:"Ladda",//充值
        withdraw:"Uttag",//提现
        myMoney:"Min plånbok",//我的钱包
        detail:"detalj",//详情
        balance:"balans",//余额
        finance:"Personliga rapporter",//个人报表
        order:"Kontouppgifter",//账户明细
        record:"Registrering av uppgifter",//任务记录
        my:"Personligt center",//个人中心
        notice:"Informationsbulletiner",//信息公告
        service:"Online kundservice",//在线客服
        score: "Poäng",
    },
    setting:{
        title:"Förbereda",//设置
        basic:"Grundläggande informationsinställningar",//基本信息设置
        password:"Logga in lösenord",//登录密码
        capital:"Lösenord för fonder",//资金密码
        lang:"Språk",//语言
        quit:"Logga ut",//退出登录
    },
    setPayPassword: {
        title:"Ange ett lösenord för pengar",//设置资金密码
        opw:"Ange fondens lösenord",//请输入资金密码
        tpw:"Ange fondlösenordet igen",//请再次输入资金密码
    },
    gameRecord:{
        reject: "avvisa",
        profit: "vinst",
        place: "lägga en order",
        title:"Registrering av uppgifter",//任务记录
        amountBet:"Insatsbelopp",//下注金额
        create:"Tid för beställning",//下单时间
        update:"Avvecklingstid",//结算时间
    },
    video:{
        title:"Video Bio",//视频影院
        play:"Leka",//播放
    },
    playVideo:{
        play: "Spelade tider",//次播放
    },
    setname:{
        title:"Ändra ditt riktiga namn",//修改真实姓名
        name:"Namn",//姓名
        truename:"Ange ditt riktiga namn",//请输入真实姓名
        hint:"För ditt kontos säkerhet måste ditt riktiga namn matcha namnet på det länkade bankkortet",//为了您账户安全,真实姓名需要与绑定银行卡姓名一致
    },
    setsex:{
        title:"Könsmodifiering",//性别修改
    },
    bindCard:{
        title:"Fyll i insamlingskortet",//填写收款卡
        msg:"Ange din betalkortsinformation",//请输入您的收款卡信息
        hint:"Kära användare, för att skydda säkerheten för dina pengar, vänligen binda ditt riktiga namn och ställ in ett uttagslösenord, om namnet inte överensstämmer med kontoöppningsnamnet kommer du inte att kunna ta ut pengar",//尊敬的用户,为了保障您的资金安全,请您绑定您的真实姓名和设置取款密码,如果姓名与开户名不一致,将无法取款
        card: "Konto (normalt)",
        truecard: "Ange ditt konto (normalt)",
        name: "Kontonamn (pseudonym)",
        truename: "Ange kontonamn (pseudonym)",
        financial_institution: "Finansinstitutens namn",
        truefinancial_institution: "Ange namnet på finansinstitutet",
        branch: "Förgreningsnamn",
        truebranch: "Ange grennamnet",
        branch_number: "Avdelningsnummer",
        truebranch_number: "Ange grennummer"
    },
    setbank:{
        title:"Information om insamlingskort",//收款卡信息
        bank:"Lägga till ett bankkort",//添加银行卡
        hint:"Tips: Vänligen binda till stora affärsbanker, om du behöver ändra, vänligen kontakta kundtjänst online",//提示:请绑定大型商业银行,如需修改,请您联系在线客服
    },
    setLoginPassword:{
        title:"Ändra inloggningslösenordet",//修改登录密码
        old_password:"Gammalt lösenord",//旧密码
        old_password_p:"Ange ditt gamla lösenord",//请输入旧密码
        o_new_password:"Nytt lösenord",//新密码
        o_new_password_p:"Ange ett nytt lösenord",//请输入新密码
        t_new_password_p:"Ange det nya lösenordet igen",//请再次输入新密码
    },
    infomation:{
        title:"Grundläggande information",//基本信息
        head:"avatar",//头像
        select:"Välj en avatar",//选择头像
        name:"Verkligt namn",//真实姓名
        sex:"genus",//性别
        man:"man",//男
        girl:"kvinna",//女
        unknown:"Okänd",//未知
        binding:"Bindande besked",//绑定信息
    },
    withdraw: {
        title:"Uttagscenter",//提现中心
        record:"Ångerrätt",//提现记录
        money:"Uttagsbelopp",//提现金额
        all:"alla",//全部
        min:"Enkel transaktionsgräns: Minsta",//单笔限额：最低
        max:"maximal",//最高
        num:"Antal uttag: Det maximala uttaget på en dag",//提现次数：一天最高提现
        hint:"Ankomsttid: I allmänhet är ankomsttiden cirka 5 minuter och den snabbaste ankomsttiden är 2 minuter",//到账时间：一般到账时间在5分钟左右，最快2分钟内到账
        quota:"Beskrivning av gränsen",//限额说明
        balance:"balans",//余额
        withdraw:"Ta ut pengar nu",//马上提现
    },
    withdrawRecord:{
        desc:"illustrera",//说明
        create:"Inlämningstid",//提交时间
        update:"Granska tid",//审核时间
    },
    personalreport:{
        title:"Personliga rapporter",//个人报表
        profit:"Vinstbelopp",//盈利金额
        formula:"Vinstberäkningsformel: Vinnande belopp - uppgiftsbelopp",//盈利计算公式 : 中奖金额 - 任务金额
        task:"Uppgiftsbelopp",//任务金额
        recharge:"Påfyllningsbelopp",//充值金额
        withdrawal:"Uttagsbelopp",//提现金额
        win_money:"Vinnande belopp",//中奖金额
    },
    service: {
        title:"Online kundservice",//在线客服
        relation:"kontakt",//联系
        hint:"7 * 24 timmar om dygnet för att tjäna dig helhjärtat",//全天7 * 24小时竭诚为您服务
    },
    msg:{
        "次":"gånger",
        "用户不存在！":"Användaren finns inte!",//用户不存在！
        "密码错误！":"Fel lösenord!",
        "登录成功！":"Inloggningen lyckades!",
        "邀请码不存在！":"Inbjudningskoden finns inte!",
        "用户名位数错误！":"Antalet siffror i användarnamnet är fel!",
        "密码位数错误！":"Fel antal siffror i lösenordet!",
        "用户名不能存在中文！":"Användarnamnet kan inte existera kinesiska!",
        "邀请码不能为空！":"Inbjudningskoden får inte vara tom!",
        "注册成功！":"Registreringen lyckades!",
        "注册失败！":"Registreringen misslyckades!",
        "用户已存在":"Användaren finns redan",
        "刷新成功！":"Uppdateringen lyckades!",
        "请联系顾问或接待员":"Kontakta en konsult eller receptionist",
        "金额错误！":"Fel mängd!",
        "请选号！":"Välj nummer!",
        "请填写金额!":"Vänligen fyll i beloppet!",
        "余额不足，请联系客服充值！":"Saldot är otillräckligt, vänligen kontakta kundtjänst för att ladda!",
        "没有更多了":"Inget mer",
        "没有数据":"Det finns inga data",
        "余额不足！":"Otillräcklig balans!",
        "扣款失败！":"Chargeback misslyckades!",
        "下注异常！":"Satsa onormalt!",
        "投注成功！":"Satsa framgång!",
        "参数异常！":"Parameter onormal!",
        "获取中":"Kom in ...",
        "请完成任务单后进入":"Fyll i uppgiftslistan och ange",
        "请联系客服充值":"Kontakta kundtjänst för att fylla på",
        "请设置收款卡!":"Ställ in ett insamlingskort!",
        "功能已禁用!":"Funktionen inaktiverad!",
        "账号下线":"Konto offline",
        "登录/注册":"Logga in/Registrering",
        "登录可享受更多服务！":"Logga in för att få fler tjänster!",
        "未设置":"Ej inställt",
        "已设置":"Ställa",
        "提交":"inlämna",
        "确定":"Är du säker",
        "请填写完整":"Vänligen fyll i fullständigt",
        "两次密码不一致！":"Lösenorden är inkonsekventa två gånger!",
        "设置提现密码成功！":"Ställ in uttagslösenord framgångsrikt!",
        "提现密码已设置，需要修改请联系客服":"Uttagslösenordet har ställts in, om du behöver ändra det, vänligen kontakta kundtjänst",
        "已绑定":"Bunden",
        "无":"inte",
        "更换头像成功！":"Avatarbyte framgångsrikt!",
        "更新头像失败！":"Det gick inte att uppdatera avataren!",
        "请勿重复设置！":"Upprepa inte inställningarna!",
        "设置姓名成功！":"Namnet har ställts in!",
        "设置姓名失败！":"Det gick inte att ange namn!",
        "设置性别成功！":"Ställ in kön framgångsrikt!",
        "设置性别失败！":"Ställ in kön misslyckades!",
        "请设置姓名后再绑定银行卡！":"Ange ditt namn innan du binder ditt bankkort!",
        "请设置提现密码后再绑定银行卡！":"Vänligen ställ in ett uttagslösenord innan du binder ett bankkort!",
        "确认绑卡":"Bekräfta bindningen",
        "请输入银行卡号！":"Ange ditt bankkortsnummer!",
        "请输入银行名称！":"Ange bankens namn!",
        "设置银行卡成功！":"Ställ in ditt bankkort framgångsrikt!",
        "设置银行卡失败！":"Det gick inte att ställa in ett bankkort!",
        "旧密码错误":"Det gamla lösenordet är fel",
        "修改密码成功！":"Ändra lösenord framgångsrikt!",
        "修改密码失败！":"Det gick inte att ändra lösenord!",
        "请填写正确的金额":"Vänligen fyll i rätt belopp",
        "提现金额不能少于：":"Uttagsbeloppet får inte understiga",
        "提现金额不能大于：":"Uttagsbeloppet får inte vara större än:",
        "当日提现次数已用完":"Antalet utbetalningar på dagen har förbrukats",
        "提现成功！":"Uttag framgångsrikt!",
        "提现失败！":"Uttag misslyckades!",
        "金额":"belopp",
        "待审核":"Ska ses över",
        "审核成功":"Granskningen lyckades",
        "审核退回":"Granska studsar",
        "请充值后观看视频！":"Fyll på och titta på videon!",
        "释放即可刷新":"Släpp för att uppdatera...",
        "加载中":"Lastning...",
        "下拉即可刷新": "Dra ner för att uppdatera...",
    },
    setLang:{
        title:"Ställ in språk"
    },
    lang:{
        "zh": "Kinesiska",
        "jp": "Japan",
        "ara": "Språk",
        "en": "Engelska",
        "spa": "Spanska",
        "it": "Italienska",
        "de": "Tyska",
        "pt": "Portugisiska",
        "fra": "Franska",
        "nl": "Nederländerna",
        "id": "Indonesiska",
        "hi": "Hindi",
        "kor": "Koreanska",
        "tr": "Turkiska",
        "swe": "Svenska",
        "ga": "Irländska",
        "ru": "Ryska",
        "vie": "Vietnamesiska",
        th: "Thailändska",
        est: "Estniska",
        be: "Vitrysslands språk",
        bul: "Bulgariens språk",
        pl: "Polska",
        ms: "Malay",
    },
    profile: {
        portrait: "Fotografi",
        video: "Video",
        jianjie:" Personlig profil",
        liji: "Omedelbart möte",
        chongdan: "Laddningsområde",
        fd: "Kvinnor",
        yuyue: "Utnämning",
        archives: "Syster fil",
        age: "Ålder",
        height: "Höjd",
        weight: "Vikt",
        cup: "Koppskydd",
        city: "Städer",
        area: "Tjänsteområde",
        item: "Tjänsteprojekt"
    },
}
export default  Swe