<template>
  <div class="container page">
    <van-nav-bar :title="$t('setname.title')" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()"/>
      </template>
      <template #right>
        <span class="nav-right" @click="save()">{{ $t("msg.确定") }}</span>
      </template>
    </van-nav-bar>
    <van-cell-group>
      <van-field v-model="name" :readonly="userInfo.name !== ''" :label="$t('setname.name') " :placeholder="$t('setname.truename')" />
    </van-cell-group>
    <p>{{ $t("setname.hint") }}</p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name:"",
      userInfo:{}
    };
  },
  methods: {
    back(){
      return window.history.back();
    },
    save(){
      if(this.userInfo.name){
        this.$toast(this.$t("msg.请勿重复设置！"));
        return true;
      }
      if(this.name === "" || this.name === null || this.name === undefined){
        this.$toast.fail(this.$t("setname.truename"));
        return false;
      }
      this.$http({
        method: 'get',
        data:{name:this.name},
        url: 'user_set_name'
      }).then(res=>{
        if(res.code === 200){
          this.getUserInfo();
          this.name = this.userInfo.name;
        }
        setTimeout(() => {
          this.$toast(this.$t("msg."+res.msg));
        }, 500);
        /*if(!this.userInfo.paypassword && this.$route.query.type == 1){
          this.$router.push("SetPayPassword?type=1");
          //this.$toast(this.$t("msg.请设置提现密码后再绑定银行卡！"));
          return true;
        } else if(this.$route.query.type == 1) {
          this.$router.push({path:'/BindCard'})
        }*/
        this.$router.push({path:'/BindCard'})
      })
    },
    getUserInfo(){
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res=>{
        if(res.code === 200){
          this.userInfo = res.data;
          this.name = res.data.name;
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    }
  },
  created() {
    if(!localStorage.getItem('token')){
      this.$router.push({path:'/Login'})
    }else {
      this.getUserInfo();
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
.van-cell {
  font-size: 18PX;
  line-height: 40PX;
}
.container p{
  padding: 0 15PX;
  margin-top: 15PX;
  font-size: 16PX;
  color: #dc2037;
}
</style>
