const De = {
    text: {
        login_txt1: "Personen unter 18-jährigem Alter dürfen es nicht verwenden",
        choose: "passend",
        bond: "Verifiziert und bezahlt eine Anzahlung von $10000",
        img: "Porträtdarstellung",
        intention: "Fußtoleranz",
        casual: "Anleitung für Casual Sex",
        txt1: "Hinweis: Diese Plattform bietet die authentischsten Luftfracht Klettverschlüsse. Um die Privatsphäre der Nutzer zu schützen, muss die Mitgliedschaft den Kundendienst kontaktieren oder von erfolgreichen Personen empfohlen werden.",
        txt2: "Beratung des Kundenservice",
        txt3: "Führer",
        txt4: "Notieren Sie die Nummer Ihrer Schwester und kontaktieren Sie den Kundenservice",
        ok: "OK",
        cancel: "Abbrechen",
        txt5: "Zum Vorteil aller Mitglieder werden nur 1% der Ressourcen auf der Website angezeigt und Updates sind unregelmäßig. Für detaillierte Informationen wenden Sie sich bitte an den Kundendienst.",
        txt6: "Alle Mitgliedsinformationen auf der Plattform sind authentisch, sicher und zuverlässig. Es kann bundesweit genutzt werden, und neue Begegnungen finden sich auch auf derselben Straße. Wählen Sie eine Frau, die Sie mögen und treffen Sie sie persönlich. Jetzt beitreten~",
        txt7: "Mitgliederinformationen",
        txt8: "passend",
        txt9: "Verwenden Sie das Protokoll",
        txt10: "Ich will mit ihr ausgehen",
        txt11: "Diese Plattform bietet die authentischste Suche nach Deflation. Um die Privatsphäre personenbezogener Daten zu schützen, kann die Registrierung nur erfolgen, indem Sie den Support kontaktieren oder Empfehlungen von Personen akzeptieren, die erfolgreich nach Deflationserfahrungen gesucht haben.",
        txt12: "Derzeit 0 Online-Kopplung",
        txt13: "44 Aktivierung",
        txt14: "Leistungen für Mitglieder",
        txt15: "Ereignis",
        txt16: "Mitglied Video",
        txt17: "Lüg ihn an.",
        txt18: "Mitgliederinformationen",
        txt19: "Neue ID",
        txt20: "treffen",
        txt21: "Abstimmung endet",
        txt22: "Ergebnisse während des Zeitraums",
        txt23: "Zeitraum",
        txt24: "Zufällige Auswahl der Zahl",
        credit_score: "Credit Score",
        mail: "Stationsbuchstabe",
        txt25: "Die Modernisierung erfordert 15 Prozentpunkte",
        txt26: "Persönliche Punkte",
        txt27: "Punkt (8361;)",
        txt28: "Punkte aufladen",
        txt29: "Standort ändern",
        txt30: "Online-Service",
        txt31: "kurze Einführung",
        txt32: "Klicken Sie auf detaillierte Informationen",
        txt33: "Austausch wieder aufgenommen",
        txt34: "Datensatz",
        txt35: "Passwort ändern",
        txt36: "Standort ändern",

    },
    login: {
        title: "Einloggen.", //登录
        username:"Bitte geben sie einen benutzernamen ein",//请输入用户名
        password:"Code eingeben.",//请输入密码
        forgetPaw:"Ich hab die kombination vergessen.",//忘记密码
        registerNow:"Kein konto? Ich brauch das schnell.",//没有账户？马上注册
        doLogin:"Einloggen.",//登录

    },
    register:{
        title: "Eine liste ausfüllen.", //注册
        username:"Bitte geben sie einen benutzernamen ein (6/12 englische buchstaben/zahlen)",//请输入用户名(6-12个英文字母或数字)
        password:"Bitte geben sie ihr einmalpasswort ein (6/12 englische buchstaben/zahlen)",//请输入登录密码(6-12个英文字母或数字)
        code:"Bitte geben sie den zugangscode ein",//请输入邀请码
        agreement:"Ich habe die verträge zur gründung einer firma kennen und mit ihnen einverstanden sein können",//我已经知晓并同意"开户协议"各项条约
        chooseAgreement:"Bitte markieren sie den eröffnungs-vertrag!",//请勾选下方开户协议！
        doRegister:"Eine liste ausfüllen.",//注册
    },
    footer:{
        home :"Die titelseite.",//首页
        game:"Die reservierungen?",//预约
        video:"Na video.",//视频
        mine:"Für mich.",//我的
    },
    game:{
        title:"Reservierung für den saal.",//预约大厅
        all:"Das ganze glas.",//全部
    },
    choose:{
        title:"Die schönheitskönigin",//选妃
        city:"großstadt",//城市大全
        rule:"Das internet ist das echte randsystem im zentrum des netzes und es gibt eine rein stadttore mit begleiter - und internetverbindungen, die die privatsphäre jedes teilnehmers nur durch die kontaktierung eines serververters Oder eines aktiven mitglieds der plattform schützen.",//本平台全网最真实外围+商务伴游+同城激情，为保证每个用户的个人隐私，客户仅限通过联系接待员或平台资深会员实名推荐才可加入。
        price:"Der prozessieren.",//价格流程
        resource:"Welche ressourcen gibt es?",//有哪些资源?
        character:"Internet-hit, model, stewardess, stewardess, college, das geht leider nicht Anders als diese plattform",//网红、模特、空姐、嫩模、大学生，只有您想不到，没有本平台办不到
        scope:"Wie weit bist du gekommen?",//服务范围?
        location:"Städte mit kostenlosen waffen können überall im land landen, an allen zwei orten des landes und an allen drei orten in verbindung mit der sprechstundenhilfe.",//同城免费约炮，任意地点全国空降，国内一二线城市当地均有，三线城市也可通过联系接待员预约安排。
    },
    home:{
        recommend:"Empfahl aufgaben",//推荐任务
        more:"Ich will mehr sehen.",//查看更多
        popularity:"Die beliebtheit zählt",//人气排行
        hot:"Heiße empfehlung.",//热门推荐
    },
    lottery:{
        jieguo: "Ergebnisse der Abstimmung",
        jiesu: "Ende",
        record:"Einsatzdaten.",//任务记录
        rule:"Und er verschlingt alles.",//玩法规则
        prompt:"Beim spielen.",//玩法提示
        gameplay:"Wählen sie die nummer aus der auswahl und der werte für wetten",//从可选和值形态里面选择号码进行下注
        explain:"Wie gewonnen?",//中奖说明
        wprole:"Die summe der drei eröffnungsnummern ist zwischen 11 und 18 groß. Eine summe Von 3 bis 10 ist klein.",//三个开奖号码总和值11~18 为大;总和值3~ 10为小;
        example:"Unser paradigma.",//投注范例
        option:"Projekt: kleine lotterienummer: 123, d. h",//投注方案：小 开奖号码：123,即中小
        task:"Einsatzbefehl.",//任务单
        money:"Verfügbares Guthaben",//可用余额
        submit:"Eingereicht werden",//提交
        selection:"Sie wählen jetzt.",//当前选号
        perNote:"Jeden betrag",//每注金额
        inputMoney:"Bitte zahlen eingeben.",//请输入金额
        total:"Insgesamt.",//总共
        note:"Insgesamt.",//总共
        statistics:"Gesamtansicht.",//合计
        empty:"Deine bestellung.",//清空订单
        verify:"Bestätige einreichung der nachricht.",//确认提交
        issue:"Damen.",//期号
        num:"Kostenpflichtige nummern.",//开奖号码
        big:"groß",//大
        small:"klein",//小
        single:"ledig",//单
        double:"Paar",//双
        unselected:"Nicht ausgewählt",//未选择
        drawLottery:"Ziehung erfolgreich, Periodennummer:",//开奖成功，期号：
        kefu:"Bitte wenden Sie sich an den Administrator, um die Aufgabe zu beanspruchen!",//请联系管理员领取该任务!
    },
    mine:{
        recharge:"aufladen",//充值
        withdraw:"Abhebungen",//提现
        myMoney:"Mein Portemonnaie",//我的钱包
        detail:"Detail",//详情
        balance:"Gleichgewicht",//余额
        finance:"Persönliche Berichte",//个人报表
        order:"Kontodaten",//账户明细
        record:"Aufgabenerfassung",//任务记录
        my:"Persönliches Zentrum",//个人中心
        notice:"Informations-Bulletins",//信息公告
        service:"Online-Kundenservice",//在线客服
        score: "Die Punkte",
    },
    setting:{
        title:"Aufstellen",//设置
        basic:"Grundlegende Informationseinstellungen",//基本信息设置
        password:"Login-Passwort",//登录密码
        capital:"Passwort für Guthaben",//资金密码
        lang:"Sprache",//语言
        quit:"Abmelden",//退出登录
    },
    setPayPassword: {
        title:"Legen Sie ein Passwort für Guthaben fest",//设置资金密码
        opw:"Bitte geben Sie das Fondspasswort ein",//请输入资金密码
        tpw:"Bitte geben Sie das Fondspasswort erneut ein",//请再次输入资金密码
    },
    gameRecord:{
        reject: "ablehnen",
        profit: "Gewinn",
        place: "eine Bestellung aufgeben",
        title:"Aufgabenerfassung",//任务记录
        amountBet:"Höhe des Einsatzes",//下注金额
        create:"Zeitpunkt der Bestellung",//下单时间
        update:"Abrechnungszeit",//结算时间
    },
    video:{
        title:"Video-Kino",//视频影院
        play:"Spielen",//播放
    },
    playVideo:{
        play: "Gespielte Zeiten",//次播放
    },
    setname:{
        title:"Ändern Sie Ihren richtigen Namen",//修改真实姓名
        name:"Name",//姓名
        truename:"Bitte geben Sie Ihren richtigen Namen ein",//请输入真实姓名
        hint:"Um die Sicherheit Ihres Kontos zu gewährleisten, muss Ihr richtiger Name mit dem Namen auf der verknüpften Bankkarte übereinstimmen",//为了您账户安全,真实姓名需要与绑定银行卡姓名一致
    },
    setsex:{
        title:"Geschlechtsmodifikation",//性别修改
    },
    bindCard:{
        title:"Füllen Sie die Abholkarte aus",//填写收款卡
        msg:"Bitte geben Sie Ihre Zahlungskarteninformationen ein",//请输入您的收款卡信息
        hint:"Sehr geehrter Benutzer, um die Sicherheit Ihres Geldes zu schützen, binden Sie bitte Ihren richtigen Namen und legen Sie ein Auszahlungspasswort fest, wenn der Name nicht mit dem Kontoeröffnungsnamen übereinstimmt, können Sie kein Geld abheben",//尊敬的用户,为了保障您的资金安全,请您绑定您的真实姓名和设置取款密码,如果姓名与开户名不一致,将无法取款
        card: "Kontonummer (normal)",
        truecard: "Geben Sie die Kontonummer ein (normal)",
        name: "Kontoname (richtiger Name)",
        truename: "Geben Sie den Namen des Kontos ein (richtiger Name)",
        financial_institution: "Name des Finanzinstituts",
        truefinancial_institution: "Geben Sie den Namen des Finanzinstituts ein",
        branch: "Name der Filiale",
        truebranch: "Geben Sie den Namen der Filiale ein",
        branch_number: "Nummer der Filiale",
        truebranch_number: "Geben Sie die Filialnummer ein"
    },
    setbank:{
        title:"Informationen zur Sammelkarte",//收款卡信息
        bank:"Fügen Sie eine Bankkarte hinzu",//添加银行卡
        hint:"Tipps: Bitte binden Sie sich an große Geschäftsbanken, wenn Sie Änderungen vornehmen müssen, wenden Sie sich bitte an den Online-Kundendienst",//提示:请绑定大型商业银行,如需修改,请您联系在线客服
    },
    setLoginPassword:{
        title:"Ändern Sie das Login-Passwort",//修改登录密码
        old_password:"Altes Passwort",//旧密码
        old_password_p:"Bitte geben Sie Ihr altes Passwort ein",//请输入旧密码
        o_new_password:"Neues Passwort",//新密码
        o_new_password_p:"Bitte geben Sie ein neues Passwort ein",//请输入新密码
        t_new_password_p:"Bitte geben Sie das neue Passwort erneut ein",//请再次输入新密码
    },
    infomation:{
        title:"Grundlegende Informationen",//基本信息
        head:"Avatar",//头像
        select:"Wählen Sie einen Avatar aus",//选择头像
        name:"Klarname",//真实姓名
        sex:"Geschlecht",//性别
        man:"Mann",//男
        girl:"frau",//女
        unknown:"Unbekannt",//未知
        binding:"Verbindliche Auskünfte",//绑定信息
    },
    withdraw: {
        title:"Entnahmestelle",//提现中心
        record:"Auszahlungshistorie",//提现记录
        money:"Auszahlungsbetrag",//提现金额
        all:"alle",//全部
        min:"Limit für einzelne Transaktionen: Minimum",//单笔限额：最低
        max:"höchst",//最高
        num:"Anzahl der Auszahlungen: Die maximale Auszahlung an einem Tag",//提现次数：一天最高提现
        hint:"Ankunftszeit: Im Allgemeinen beträgt die Ankunftszeit etwa 5 Minuten und die schnellste Ankunftszeit 2 Minuten",//到账时间：一般到账时间在5分钟左右，最快2分钟内到账
        quota:"Beschreibung des Limits",//限额说明
        balance:"Gleichgewicht",//余额
        withdraw:"Jetzt auszahlen lassen",//马上提现
    },
    withdrawRecord:{
        desc:"illustrieren",//说明
        create:"Einreichungszeit",//提交时间
        update:"Überprüfungszeit",//审核时间
    },
    personalreport:{
        title:"Persönliche Berichte",//个人报表
        profit:"Höhe des Gewinns",//盈利金额
        formula:"Formel zur Gewinnberechnung : Gewinnbetrag - Aufgabenbetrag",//盈利计算公式 : 中奖金额 - 任务金额
        task:"Anzahl der Aufgaben",//任务金额
        recharge:"Aufladebetrag",//充值金额
        withdrawal:"Auszahlungsbetrag",//提现金额
        win_money:"Gewinnbetrag",//中奖金额
    },
    service: {
        title:"Online-Kundenservice",//在线客服
        relation:"Kontakt",//联系
        hint:"7 * 24 Stunden am Tag, um Ihnen von ganzem Herzen zu dienen",//全天7 * 24小时竭诚为您服务
    },
    msg:{
        "次":"mal",
        "用户不存在！":"Der Benutzer existiert nicht!",//用户不存在！
        "密码错误！":"Falsches Passwort!",
        "登录成功！":"Login erfolgreich!",
        "邀请码不存在！":"Einladungscode existiert nicht!",
        "用户名位数错误！":"Die Anzahl der Ziffern des Benutzernamens ist falsch!",
        "密码位数错误！":"Falsche Ziffernzahl im Passwort!",
        "用户名不能存在中文！":"Der Benutzername kann nicht chinesisch sein!",
        "邀请码不能为空！":"Der Einladungscode darf nicht leer sein!",
        "注册成功！":"Anmeldung erfolgreich!",
        "注册失败！":"Registrierung fehlgeschlagen!",
        "用户已存在":"Benutzer existiert bereits",
        "刷新成功！":"Refresh erfolgreich!",
        "请联系顾问或接待员":"Bitte wenden Sie sich an einen Berater oder eine Rezeptionistin",
        "金额错误！":"Falscher Betrag!",
        "请选号！":"Bitte wählen Sie die Anzahl!",
        "请填写金额!":"Bitte geben Sie den Betrag ein!",
        "余额不足，请联系客服充值！":"Das Guthaben reicht nicht aus, bitte wenden Sie sich zum Aufladen an den Kundendienst!",
        "没有更多了":"Nicht mehr",
        "没有数据":"Es gibt keine Daten",
        "余额不足！":"Unzureichende Balance!",
        "扣款失败！":"Rückbuchung fehlgeschlagen!",
        "下注异常！":"Wetten Sie abnormal!",
        "投注成功！":"Wetten: Erfolg!",
        "参数异常！":"Parameter abnormal!",
        "获取中":"Reinkommen...",
        "请完成任务单后进入":"Bitte füllen Sie die Aufgabenliste aus und geben Sie Folgendes ein:",
        "请联系客服充值":"Bitte wenden Sie sich an den Kundenservice, um das Aufladen zu ermöglichen",
        "请设置收款卡!":"Richten Sie eine Sammelkarte ein!",
        "功能已禁用!":"Funktion deaktiviert!",
        "账号下线":"Konto offline",
        "登录/注册":"Login/Registrierung",
        "登录可享受更多服务！":"Melden Sie sich an, um weitere Dienstleistungen zu genießen!",
        "未设置":"Nicht festgelegt",
        "已设置":"Garnitur",
        "提交":"Senden",
        "确定":"Bist du sicher",
        "请填写完整":"Bitte füllen Sie das Formular vollständig aus",
        "两次密码不一致！":"Die Passwörter sind doppelt inkonsistent!",
        "设置提现密码成功！":"Auszahlungspasswort erfolgreich setzen!",
        "提现密码已设置，需要修改请联系客服":"Das Auszahlungspasswort wurde festgelegt, wenn Sie es ändern müssen, wenden Sie sich bitte an den Kundendienst",
        "已绑定":"Gebunden",
        "无":"nicht",
        "更换头像成功！":"Avatar-Ersatz erfolgreich!",
        "更新头像失败！":"Fehler beim Aktualisieren des Avatars!",
        "请勿重复设置！":"Wiederholen Sie die Einstellungen nicht!",
        "设置姓名成功！":"Name erfolgreich gesetzt!",
        "设置姓名失败！":"Name konnte nicht festgelegt werden!",
        "设置性别成功！":"Legen Sie das Geschlecht erfolgreich fest!",
        "设置性别失败！":"Geschlecht einstellen fehlgeschlagen!",
        "请设置姓名后再绑定银行卡！":"Legen Sie Ihren Namen fest, bevor Sie Ihre Bankkarte binden!",
        "请设置提现密码后再绑定银行卡！":"Bitte legen Sie ein Auszahlungspasswort fest, bevor Sie eine Bankkarte binden!",
        "确认绑卡":"Bestätigen Sie die Bindung",
        "请输入银行卡号！":"Bitte geben Sie Ihre Bankkartennummer ein!",
        "请输入银行名称！":"Bitte geben Sie den Namen der Bank ein!",
        "设置银行卡成功！":"Richten Sie Ihre Bankkarte erfolgreich ein!",
        "设置银行卡失败！":"Das Einrichten einer Bankkarte ist fehlgeschlagen!",
        "旧密码错误":"Das alte Passwort ist falsch",
        "修改密码成功！":"Passwort erfolgreich ändern!",
        "修改密码失败！":"Passwort konnte nicht geändert werden!",
        "请填写正确的金额":"Bitte geben Sie den richtigen Betrag ein",
        "提现金额不能少于：":"Der Auszahlungsbetrag darf nicht geringer sein als:",
        "提现金额不能大于：":"Der Auszahlungsbetrag darf nicht höher sein als:",
        "当日提现次数已用完":"Die Anzahl der Auszahlungen an diesem Tag ist erschöpft",
        "提现成功！":"Auszahlung erfolgreich!",
        "提现失败！":"Auszahlung fehlgeschlagen!",
        "金额":"Menge",
        "待审核":"Zu überprüfen",
        "审核成功":"Überprüfung erfolgreich",
        "审核退回":"Bounces von Bewertungen",
        "请充值后观看视频！":"Bitte laden Sie auf und schauen Sie sich das Video an!",
        "释放即可刷新":"Zum Auffrischen loslassen...",
        "加载中":"Laden...",
        "下拉即可刷新": "Dropdown zum Aktualisieren...",
    },
    setLang:{
        title:"Legen Sie die Sprache fest"
    },
    profile: {
        portrait: "Fotografie",
        video: "VIDEO",
        jianjie: "Persönliches Profil",
        liji: "Jetzt buchen",
        chongdan: "Ladebereich",
        fd: "The Lady",
        yuyue: "Terminvereinbarung",
        archives: "Die Schwester Archiv",
        age: "Alter",
        height: "Höhe",
        weight: "Gewicht",
        cup: "Die Tasse",
        city: "Die Stadt",
        area: "Service Area",
        item: "Dienstleistungen"
    },
    lang:{
        "zh": "Chinesisch",
        "jp": "Japan",
        "ara": "Sprache",
        "en": "English",
        "spa": "Spanish",
        "it": "Italienisch",
        "de": "German",
        "pt": "Portugiesisch",
        "fra": "Französisch",
        "nl": "Niederländisch",
        "id": "Indonesisch",
        "hi": "Hindi",
        "kor": "Koreanisch",
        "tr": "Türkisch",
        "swe": "Swedish",
        "ga": "Irish",
        "ru": "Russian",
        "vie": "Vietnamesisch",
        th: "Thai",
        est: "Estnisch",
        be: "Weißrussisch",
        bul: "Bulgarisch",
        pl: "Polnisch",
        ms: "Malaiisch",
    }
}
export default  De