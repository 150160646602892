const Ms = {
    text: {
        login_txt1: "*Tidak tersedia untuk mereka yang berumur di bawah 18 tahun",
        choose: "sepadan",
        bond: "Disahkan, deposit $10,000 telah dibayar",
        img: "Paparan imej",
        intention: "Rasai kepuasan",
        casual: "arahan seks kasual",
        txt1: "Nota: Platform ini menawarkan sambungan bawaan udara yang paling tulen. Untuk melindungi privasi pengguna, keahlian memerlukan menghubungi perkhidmatan pelanggan atau cadangan orang yang berjaya.",
        txt2: "Rujuk khidmat pelanggan",
        txt3: "Maklumat",
        txt4: "Sila ambil perhatian nombor kakak anda dan hubungi khidmat pelanggan",
        ok: "OK",
        cancel: "batalkan",
        txt5: "Untuk ahli kami, hanya 1% daripada sumber dipaparkan di tapak dan kemas kini adalah tidak teratur. Untuk lebih banyak sumber, sila hubungi perkhidmatan pelanggan.",
        txt6: "Semua maklumat ahli di platform adalah sahih, selamat dan boleh dipercayai. Ia tersedia di seluruh negara, malah anda boleh bertemu orang baharu di bandar yang sama. Pilih wanita kegemaran anda dan temui dia secara peribadi. Sertai sekarang!",
        txt7: "Maklumat ahli",
        txt8: "sepadan",
        txt9: "syarat perkhidmatan",
        txt10: "saya nak dating dengan dia",
        txt11: "Platform kami menyediakan cara paling realistik untuk mencari rakan seks. Untuk melindungi privasi maklumat peribadi anda, hanya mereka yang telah menghubungi sokongan atau menerima cadangan nama sebenar daripada seseorang yang telah berjaya menemui rakan seks boleh mendaftar.",
        txt12: "Pada masa ini 0 gandingan dalam talian",
        txt13: "44 diaktifkan",
        txt14: "hak keahlian",
        txt15: "peristiwa",
        txt16: "video ahli",
        txt17: "menunggang dia dan menipu",
        txt18: "Maklumat ahli",
        txt19: "ID baharu",
        txt20: "perjumpaan",
        txt21: "Undian ditutup",
        txt22: "keputusan tempoh",
        txt23: "tempoh",
        txt24: "nombor loteri",
        credit_score: "Skor kredit",
        mail: "surat stesen",
        txt25: "Peningkatan memerlukan 15 mata",
        txt26: "mata peribadi",
        txt27: "Mata (₩)",
        txt28: "Mata caj",
        txt29: "Tebus mata",
        txt30: "perkhidmatan dalam talian",
        txt31: "profil",
        txt32: "Butiran dot",
        txt33: "Sejarah pertukaran",
        txt34: "rekod data",
        txt35: "tukar kata laluan",
        txt36: "Tebus mata",
    },
    login: {
        title: "Log masuk",
        username: "Sila masukkan nama pengguna",
        password: "Sila masukkan kata laluan",
        forgetPaw: "Lupa Kata Laluan",
        registerNow: "Tiada akaun? Daftar sekarang",
        doLogin: "Log masuk",

    },
    register:{
        title: "Pendaftaran",
        username: "Sila masukkan nama pengguna (6-12 huruf atau nombor)",
        password: "Sila masukkan kata laluan masuk (6-12 huruf atau nombor)",
        code: "Sila masukkan kod jemputan",
        agreement: "Saya sudah tahu dan bersetuju dengan perjanjian Perjanjian Pembukaan Akaun",
        chooseAgreement: "Sila semak perjanjian pembukaan akaun di bawah!",
        doRegister: "Pendaftaran",

    },
    footer:{
        home : "Laman Utama",
        game: "Temujanji",
        video: "Video",
        mine: "Saya",

    },
    game:{
        title: "Dewan tempahan",
        all: "Semua",

    },
    choose:{
        title: "Gundik",
        city: "Ensiklopedia bandar",
        rule: "Platform ini adalah yang paling sahih + pengiring perniagaan + semangat di bandar yang sama. Untuk memastikan privasi peribadi setiap pengguna, pelanggan hanya boleh menyertai dengan menghubungi resepsionis atau ahli kanan platform untuk cadangan nama sebenar.",
        price: "Aliran harga",
        resource: "Apakah sumber yang ada?",
        character: "Selebriti internet, model, pramugari, model muda, pelajar kolej, hanya anda yang tidak dapat memikirkannya, tetapi anda tidak boleh melakukannya tanpa Ben Ping",
        scope: "Skop perkhidmatan?",
        location: "Tempahan senjata percuma di bandar yang sama, udara di mana-mana lokasi di seluruh negara, boleh didapati secara tempatan di bandar-bandar tingkat pertama dan kedua di China, dan bandar-bandar tingkat ketiga juga boleh membuat janji temu dengan menghubungi penyambut tetamu.",

    },
    home:{
        recommend: "Tugas Cadangan",
        more: "LIHAT LEBIH LANJUT",
        popularity: "Kedudukan populariti",
        hot: "Testimoni popular",

    },
    lottery:{
        jieguo: "Keputusan undi",
        jiesu: "Akhir",
        record: "Rekod Tugas",
        rule: "Peraturan permainan",
        prompt: "Petua untuk bermain",
        gameplay: "Pilih nombor dari bentuk pilihan dan nilai untuk bertaruh",
        explain: "Penjelasan kemenangan",
        wprole: "Nilai keseluruhan tiga nombor loteri 11-18 adalah besar; Nilai keseluruhan 3 ~ 10 adalah kecil;",
        example: "Contoh taruhan",
        option: "Pelan pertaruhan: Nombor loteri kecil: 123, iaitu kecil dan sederhana",
        task: "Senarai tugas",
        money: "Baki yang ada",
        submit: "Hantar",
        selection: "Pemilihan Nombor Semasa",
        perNote: "Jumlah setiap pertaruhan",
        inputMoney: "Sila masukkan jumlah",
        total: "Jumlah",
        note: "Jumlah",
        statistics: "Jumlah",
        empty: "Kosongkan Pesanan",
        verify: "Sahkan penyerahan",
        issue: "Tempoh",
        num: "Nombor Cabutan",
        big: "Besar",
        small: "Kecil",
        single: "Tunggal",
        double: "Berganda",
        unselected: "Tidak Dipilih",
        drawLottery: "Cabutan berjaya, nombor tempoh:",
        kefu: "Sila hubungi pentadbir untuk tugas ini!",

    },
    mine:{
        recharge: "Top-up",
        withdraw: "Pengeluaran",
        myMoney: "Dompet saya",
        detail: "Butiran",
        balance: "Baki",
        finance: "Laporan peribadi",
        order: "Butiran Akaun",
        record: "Rekod Tugas",
        my: "Pusat peribadi",
        notice: "Buletin maklumat",
        service: "Perkhidmatan pelanggan dalam talian",
        score: "Mata",
    },
    setting:{
        title: "Tetapan",
        basic: "Tetapan maklumat asas",
        password: "Kata Laluan Log Masuk",
        capital: "Kata laluan dana",
        lang: "Bahasa",
        quit: "Keluar dari Log Masuk",

    },
    setPayPassword: {
        title: "Tetapkan kata laluan dana",
        opw: "Sila masukkan kata laluan dana",
        tpw: "Sila masukkan kata laluan dana sekali lagi",

    },
    gameRecord:{
        reject: "menolak",
        profit: "keuntungan",
        place: "Buat pesanan",
        title: "Rekod Tugas",
        amountBet: "Jumlah pertaruhan",
        create: "Masa untuk membuat pesanan",
        update: "Masa penyelesaian",

    },
    video:{
        title: "Pawagam video",
        play: "Main",

    },
    playVideo:{
        play: "Main semula",

    },
    setname:{
        title: "Ubah suai nama sebenar",
        name: "Nama",
        truename: "Sila masukkan nama sebenar",
        hint: "Untuk keselamatan akaun anda, nama sebenar mestilah sama dengan nama kad bank terikat",

    },
    setsex:{
        title: "Pengubahsuaian jantina",

    },
    bindCard:{
        title: "Isi kad resit",
        msg: "Sila masukkan maklumat kad resit anda",
        hint: "Pengguna yang dihormati, untuk memastikan keselamatan dana anda, sila ikat nama sebenar anda dan tetapkan kata laluan pengeluaran. Jika nama tidak konsisten dengan nama akaun, anda tidak akan dapat mengeluarkan wang",
        card: "Akaun (normal)",
        truecard: "Masukkan akaun (normal)",
        name: "Nama akaun (nama samaran)",
        truename: "Masukkan nama akaun (nama samaran)",
        financial_institution: "Nama institusi kewangan",
        truefinancial_institution: "Masukkan nama institusi kewangan",
        branch: "Nama cawangan",
        truebranch: "Masukkan nama cawangan",
        branch_number: "Nombor Cawangan",
        truebranch_number: "Masukkan nombor cawangan"
    },
    setbank:{
        title: "Maklumat kad resit",
        bank: "Tambah Kad Bank",
        hint: "Petua: Sila mengikat bank perdagangan yang besar, jika anda perlu mengubah suai, sila hubungi perkhidmatan pelanggan dalam talian",

    },
    setLoginPassword:{
        title: "Ubah suai kata laluan masuk",
        old_password: "Kata Laluan Lama",
        old_password_p: "Sila masukkan kata laluan lama",
        o_new_password: "Kata Laluan Baru",
        o_new_password_p: "Sila masukkan kata laluan baru",
        t_new_password_p: "Sila masukkan kata laluan baru sekali lagi",

    },
    infomation:{
        title: "Maklumat asas",
        head: "Avatar",
        select: "Pilih Avatar",
        name: "Nama sebenar",
        sex: "Jantina",
        man: "Lelaki",
        girl: "Wanita",
        unknown: "Tidak diketahui",
        binding: "Maklumat Mengikat",

    },
    withdraw: {
        title: "Pusat pengeluaran",
        record: "Rekod pengeluaran",
        money: "Jumlah pengeluaran",
        all: "Semua",
        min: "Had tunggal: minimum",
        max: "",
        num: "Pengeluaran: Pengeluaran dalam satu hari",
        hint: "Masa ketibaan: Secara amnya, masa ketibaan adalah sekitar 5 minit, dan masa terpantas adalah 2 minit",
        quota: "Huraian had",
        balance: "Baki",
        withdraw: "Tarik diri sekarang",

    },
    withdrawRecord:{
        desc: "Huraian",
        create: "Masa untuk menghantar",
        update: "Masa audit",

    },
    personalreport:{
        title: "Laporan peribadi",
        profit: "Jumlah keuntungan",
        formula: "Formula pengiraan keuntungan: jumlah kemenangan-jumlah tugas",
        task: "Jumlah tugas",
        recharge: "Jumlah tambah nilai",
        withdrawal: "Jumlah pengeluaran",
        win_money: "Jumlah kemenangan",

    },
    service: {
        title: "Perkhidmatan pelanggan dalam talian",
        relation: "Hubungi",
        hint: "Didedikasikan untuk anda 7 * 24 jam sehari",

    },
    profile: {
        portrait: "Fotografi",
        video: "Video",
        jianjie: "Profil",
        liji: "Buat temujanji sekarang",
        chongdan: "Julat Pengecasan",
        fd: "Wanita",
        yuyue: "Temujanji",
        archives: "Fail Gadis",
        age: "Umur",
        height: "Tinggi",
        weight: "Berat badan",
        cup: "Cawan",
        city: "Bandar",
        area: "Kawasan Perkhidmatan",
        item: "Perkhidmatan"
    },
    msg:{
        "次": "Masa",
        "用户不存在！": "Pengguna tidak wujud!",
        "密码错误！": "Kata laluan salah!",
        "登录成功！": "Log masuk berjaya!",
        "邀请码不存在！": "Kod jemputan tidak wujud!",
        "用户名位数错误！": "Bilangan digit nama pengguna salah!",
        "密码位数错误！": "Bit kata laluan salah!",
        "用户名不能存在中文！": "Nama pengguna tidak boleh wujud dalam bahasa Cina!",
        "邀请码不能为空！": "Kod jemputan tidak boleh kosong!",
        "注册成功！": "Pendaftaran berjaya!",
        "注册失败！": "Pendaftaran gagal!",
        "用户已存在": "Pengguna sudah wujud",
        "刷新成功！": "Refresh berjaya!",
        "请联系顾问或接待员": "Sila hubungi perunding atau penyambut tetamu",
        "金额错误！": "Jumlah yang salah!",
        "请选号！": "Sila pilih nombor!",
        "请填写金额!": "Sila isi jumlahnya!",
        "余额不足，请联系客服充值！": "Baki tidak mencukupi, sila hubungi perkhidmatan pelanggan untuk mengisi semula!",
        "没有更多了": "Tiada lagi",
        "没有数据": "Tiada data",
        "余额不足！": "Baki tidak mencukupi!",
        "扣款失败！": "Potongan gagal!",
        "下注异常！": "Pertaruhan tidak normal!",
        "投注成功！": "Pertaruhan berjaya!",
        "参数异常！": "Parameter tidak normal!",
        "获取中": "Mendapatkan...",
        "请完成任务单后进入": "Sila lengkapkan senarai tugas",
        "请联系客服充值": "Sila hubungi perkhidmatan pelanggan untuk mengisi semula",
        "请设置收款卡!": "Sila sediakan kad resit!",
        "功能已禁用!": "Fungsi dilumpuhkan!",
        "账号下线": "Akaun luar talian",
        "登录/注册": "Log Masuk/ Daftar",
        "登录可享受更多服务！": "Log masuk untuk lebih banyak perkhidmatan!",
        "未设置": "Tidak Ditetapkan",
        "已设置": "Set",
        "提交": "Hantar",
        "确定": "Menentukan",
        "请填写完整": "Sila isi dengan lengkap",
        "两次密码不一致！": "Kata laluan tidak konsisten dua kali!",
        "设置提现密码成功！": "Menetapkan kata laluan pengeluaran berjaya!",
        "提现密码已设置，需要修改请联系客服": "Kata laluan pengeluaran telah ditetapkan, sila hubungi perkhidmatan pelanggan jika anda perlu mengubahnya",
        "已绑定": "Terikat",
        "无": "Tiada",
        "更换头像成功！": "T Tukar Avatar Berjaya!",
        "更新头像失败！": "Gagal mengemas kini avatar!",
        "请勿重复设置！": "Jangan ulangi tetapan!",
        "设置姓名成功！": "Menetapkan nama berjaya!",
        "设置姓名失败！": "Gagal menetapkan nama!",
        "设置性别成功！": "Tetapkan jantina untuk berjaya!",
        "设置性别失败！": "Gagal menetapkan jantina!",
        "请设置姓名后再绑定银行卡！": "Sila tetapkan nama sebelum mengikat kad bank!",
        "请设置提现密码后再绑定银行卡！": "Sila tetapkan kata laluan pengeluaran sebelum mengikat kad bank!",
        "确认绑卡": "Sahkan ikatan kad",
        "请输入银行卡号！": "Sila masukkan bank!",
        "请输入银行名称！": "Sila masukkan nama bank!",
        "设置银行卡成功！": "Menetapkan kad bank berjaya!",
        "设置银行卡失败！": "Gagal menyediakan kad bank!",
        "旧密码错误": "Kata laluan lama tidak betul",
        "修改密码成功！": "Ubah suai kata laluan berjaya!",
        "修改密码失败！": "Gagal mengubah suai kata laluan!",
        "请填写正确的金额": "Sila isi jumlah yang betul",
        "提现金额不能少于：": "Jumlah pengeluaran tidak boleh kurang daripada:",
        "提现金额不能大于：": "Jumlah pengeluaran tidak boleh lebih besar daripada:",
        "当日提现次数已用完": "Pengeluaran pada hari yang sama telah digunakan",
        "提现成功！": "Pengeluaran berjaya!",
        "提现失败！": "Pengeluaran gagal!",
        "金额": "Jumlah",
        "待审核": "Belum selesai",
        "审核成功": "Audit berjaya",
        "审核退回": "Semak pulangan",
        "请充值后观看视频！": "Sila tonton video selepas tambah nilai!",
        "释放即可刷新": "Dilepaskan untuk Muat Semula...",
        "下拉即可刷新": "Tarik ke bawah untuk muat semula...",
        "加载中": "Muat...",

    },
    setLang:{
        title: "Tetapkan Bahasa"
    },
    lang:{
        zh: "Cina",
        jp: "Jepun",
        ara: "Slanga",
        en: "Bahasa Inggeris",
        spa: "Sepanyol",
        it: "Itali",
        de: "Jerman",
        pt: "Portugis",
        fra: "Perancis",
        nl: "Belanda",
        id: "Indonesia",
        hi: "Hindi",
        kor: "Korea",
        tr: "Turki",
        swe: "Sweden",
        ga: "Ireland",
        ru: "Rusia",
        vie: "Vietnam",
        th: "Thai",
        est: "Estonia",
        be: "Belarus",
        bul: "Bulgaria",
        pl: "Poland",
        ms: "Bahasa melayu",

    }
}
export default Ms