<template>
  <div class="bg">
    <div class="login">
      <div class="head"><i class="van-badge__wrapper van-icon van-icon-close"  @click="back()"></i><span
          class="van-popover__wrapper"><button
          class="van-button van-button--default van-button--normal"  @click="toLang()"
          style="color: rgb(255, 255, 255); background: none;font-size: 16px;padding: 0 15px;    height: 45px;"><div
          class="van-button__content"><span
          class="van-button__text">{{ nameLangs() }} </span></div></button></span>
      </div>
      <img  :src="this.$store.getters.getBaseInfo.login_ico !==undefined ?this.$store.getters.getBaseInfo.login_ico:'/img/null.png'">
      <form>
        <div class="ipt">
          <i class="van-badge__wrapper van-icon" style="color: rgb(153, 153, 153); font-size: 20px;"><img

            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAASCAYAAAC5DOVpAAAAAXNSR0IArs4c6QAAAw9JREFUOE+V01toE1kcx/HvmSSN0WrVtK7WBVeNvtRWKypIBS/1Qenuy0K01suLVMVbJRW1ijXbCNJ6w1tFV3ZVUNRQBUXwUiuiFsGKom1fvCAIjbcg8ZaaJnPkzHSaRgPL/l9mzgznM7//OWcEgAShrv+3hDE1WeIn6Nw5jV+nZjAxZzgO5xJi8UZq7zTjv6WDP2WyxVhoL8xvppPbNgILkfI9unyGTRsJuIxxOLqcnJ1v0qEK7Ma6oedr+zNyYDOJRCVlZddT2q6v/4Xs7GvMC44n2N6dMDWpkPi1nkmdmzw4nQeY+VdJ2vW7uS3E48e/sX17J8E8ifq4W9NIJBK43Z+SmLdNcPz4eFyuaiav8KbF7h8JcffJGPYHPhrvz569ixBZQCdCTDQxBb3LEzRu/RtEJgU1C9NirdXtSHma4kCN8T6nzWxXpQSE9HptxgOFXdlSQSR6j6ItLemT1Zbg0L8w3ddE/1wT6gWamIJUXa76Hbs2iTxfgLg79ezlD9O4UH6KV6+rKDn6AleHTAGDeTKJfeoQDBmncWnVSS62rsF3wVwXVcMighs7lqGLd0xY30CGW/Jg83zsYhSaphJ+oLS0PhWL5goa1+UzdMABiiqLU1pt3hPm8OXh1LVEsYcl/6wdQF+HHZeUOF06VWvCQs7w241JKlnEqRnttfjmMDiziqLKGRR6bBxa2cGdpxPYcDhEKEsamCqV0Go3p02amIJUqlhYGFjrxgXYtLHM2mTu2u3dTbx8W87i2ufG2AItTD0b9UFPYvs3DGLSiJU4bH8Si1+nfF817V/MBEuLnZRPPYamjeBr7BiBq6c43xj7MZ2Ql1r6MrfwIJoYQiR6lKehh1Qcek8sK7mbXZ/N+xMVuYzOnkY/52q+JU5T4DvIozovfTJKmB1YLGQ8cZKu+BXy1zcYLaqd6w0pxMIcmWbSPzx2NpfuIha7yZS6BmvdhNT1JsbVzOlZr//CMiIm6CnU+HfRCQpqypJYV9dehM2D9bvruqBn0H04pC4QxnkCdPOiztfXb2eYUhu0sO93BVYkZoYTLAAAAABJRU5ErkJggg=="
            class="van-icon__image"></i><input :placeholder="$t('login.username')"  v-model="username"
                                               type="text"></div>
        <div class="ipt"><i class="van-badge__wrapper van-icon"
                            style="font-size: 20px;"><img
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAARCAYAAADtyJ2fAAAAAXNSR0IArs4c6QAAAoxJREFUOE91k19IFFEUxr87M+66jlqkCxEVST0EQpgGuSC4RWYlUoorpYX20kOUIYhLWbJIliFWGEXUiyKBqGVimFmRkPknCSswQmlJol62zNx13V1n5sSdcddUui/3D+d3vu/cey4jgCE8HG0CHhzZBUm6BMa2ARBA9BkB9RpKOofRXqiFQ5kBugx4vsqOaKkRoVAVPJ4B/cy6PgMmsQYBpRyW2n4DdBEjuAR9/eVEHJKSniOgnsOh2tGIC77oqkxDXHQjpqaykdQ8y48M0DHO0NS0ExbLTdQ070P3kLoMzLWJqC59gYDiREnRMNqTiZHDIcKTzPDEmYlocyV21OQi9Gupbp7BlED4WN2NYOgWcup6YR0nRnaXBO8PhpfXMxEvV+DwvfxlauFN16lH8PlvY095L+I2EKNXY2uRnpyHKCELgmCHovXxEkCaocoE4ncLQdgPYABB5RnGJjsZaVoPNPqJuWAPCATGRKgqA8RFLRUQRQKRCgYGiykbgrCJg250vN+NGy0+hNYwLPh0JY8Sq89WyUd6hqhYgukP4WyxjGNpI4w0cuPxYDrqO7xhkEPr+soyuYPfR++/TvR6tAh4oVhGTuqQAbaO2NDQ6+VqHLLWFybgwPZPek/1T6R6zrR+15W5qnOvjIKMwSWwrsMXtme2bTTF3y19CAbR62zLDzydCERsV+TJOG57swpkql+vLfHD5QYQTNP2K2XajJ9IjCFd9X+g/gKqnyWOuE5DENl01tU7qhd6c68ANTda39rwj1UORqVtkXjwwruvCleLgBeLYlCQwmvUJvFt5iR6RifmJNF49FBwRcuZdVBWVMLBlK3YbG1hNDtfAtl8HuAfbHn8qtajxZj5YMNfzQA5+DtACFwAAAAASUVORK5CYII="
            class="van-icon__image"></i><input
            :placeholder="$t('login.password')" v-model="password"
            :type="passwordType"><i
            class="van-icon van-icon-eye-o"
            style="color: rgb(151, 151, 153); font-size: 20px; display: none;">
          <!----></i>
          <van-icon :name="passwordType === 'password' ? 'closed-eye':'eye-o'" style="font-size: 20px;" @click="switchPasswordType"/></div>
      </form>
      <div class="forget"></div>
      <div class="res"><a @click="toRegister">{{ $t("login.registerNow") }}</a></div>
      <div class="btn active" @click="doLogin()">{{ $t("login.doLogin") }}</div>
      <div style="margin-top: 20px">{{ $t("text.login_txt1") }}</div>
    </div>
  </div>
</template>

<script>

export default {
  model: {
    prop: 'inputValue',
    event: 'input'
  },
  props: {
    /**
     * 当前输入的值
     */
    inputValue: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      lang: "",
      username: '',
      password: this.inputValue,
      passwordType: 'password',
      list:{
        "zh": "中文 ",
        "jp": "にほんご",
        "ara": "بالعربية ",
        "en": "English ",
        "spa": "Español ",
        "it": "Italiano ",
        "de": "Deutsch ",
        "pt": "Português ",
        "fra": "Français",
        "nl": "Nederlands",
        "id": "Indonesia",
        "hi": "हिंदी भाषा",
        "kor": "한국어",
        "tr": "Türkçe",
        "swe": "Svenska språket",
        "ga": "Íris",
        "ru": "Русский язык",
        "vie": "Tiếng Việt",
        "th": "ภาษาไทย",
        est: "Eesti keel",
        be: "Беларуская мова",
        bul: "Български",
        pl: "Język polski",
        ms: "Bahasa Malay",
      }
    };
  },
  methods: {
    nameLangs(){
      return this.list[this.lang];
    },
    switchPasswordType() {
      this.passwordType = this.passwordType === 'password' ? 'text' : 'password'
    },
    back() {
      this.$router.push("/")
    },
    toLang(){
      this.$router.push({path:'/SetLang'});
    },
    toRegister() {
      this.$router.push("Register")
    },
    doLogin() {
      if (this.username === "" || this.username === null || this.username === undefined) {
        this.$toast(this.$t('login.username') + "!");
        return false;
      }
      if (this.password === "" || this.password === null || this.password === undefined) {
        this.$toast(this.$t('login.password') + "!");
        return false;
      }
      this.$http({
        method: 'get',
        data: {username: this.username, password: this.password},
        url: 'member_login'
      }).then(res => {
        console.log(res);
        if (res.code === 200) {
          console.log(res.data.id);
          this.$toast.success(this.$t("msg." + res.msg));
          localStorage.setItem('token', res.data.id)
          this.$router.push("Mine")
        } else {
          this.$toast(this.$t("msg." + res.msg));
        }
      })
    }
  },
  created() {
    this.lang = localStorage.getItem('lang');
    if (localStorage.getItem('token')) {
      return window.history.back();
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";

</style>
