const Pl = {
    text: {
        login_txt1: "Osoby poniżej 18-letniego roku nie mogą z niego korzystać",
        choose: "dopasowanie",
        bond: "zweryfikowany i wpłacony depozyt w wysokości $10000",
        img: "Reprezentacja portretu",
        intention: "Tolerancja stóp",
        casual: "Instrukcje dotyczące przypadkowego seksu",
        txt1: "Uwaga: Ta platforma zapewnia najbardziej autentyczne produkty z hakiem i pętlą frachtu lotniczego. Aby chronić prywatność użytkowników, członkostwo wymaga kontaktu z obsługą klienta lub rekomendowanego przez osoby odnoszące sukcesy.",
        txt2: "Doradztwo obsługi klienta",
        txt3: "przewodnik",
        txt4: "Zapisz numer siostry i skontaktuj się z obsługą klienta",
        ok: "OK",
        cancel: "anuluj",
        txt5: "Dla korzyści wszystkich członków, tylko 1% zasobów są wyświetlane na stronie internetowej, a aktualizacje są nieregularne. Aby uzyskać szczegółowe zasoby, skontaktuj się z obsługą klienta.",
        txt6: "Wszystkie informacje o członkach platformy są autentyczne, bezpieczne i wiarygodne. Można go używać w całym kraju, a nowe spotkania można również znaleźć na tej samej ulicy. Wybierz kobietę, którą lubisz i poznaj ją osobiście. Dołącz teraz~",
        txt7: "Informacje o członkach",
        txt8: "dopasowanie",
        txt9: "Użyj protokołu",
        txt10: "Chcę iść z nią na randkę.",
        txt11: "Platforma ta zapewnia najbardziej autentyczne wyszukiwanie deflacji. Aby chronić prywatność danych osobowych, rejestracja może być dokonana tylko poprzez kontakt z pomocą techniczną lub akceptację rekomendacji prawdziwych imion osób, które pomyślnie wyszukały doświadczenia deflacji.",
        txt12: "Obecnie 0 parowanie online",
        txt13: "44 aktywacja",
        txt14: "Świadczenia członkowskie",
        txt15: "zdarzenie",
        txt16: "Video członków",
        txt17: "Okłamuj go.",
        txt18: "Informacje o członkach",
        txt19: "Nowy identyfikator",
        txt20: "spotkać",
        txt21: "Koniec głosowania",
        txt22: "Wyniki w okresie",
        txt23: "okres",
        txt24: "Losowo wybierz liczbę",
        credit_score: "Punkty kredytowe",
        mail: "List stacji",
        txt25: "Aktualizacja wymaga 15 punktów procentowych",
        txt26: "Punkty osobiste",
        txt27: "Punkt ( 8361;)",
        txt28: "Ładowanie punktów",
        txt29: "Zmień lokalizację",
        txt30: "usługa online",
        txt31: "krótkie wprowadzenie",
        txt32: "Kliknij na szczegółowe informacje",
        txt33: "Wznowienie wymiany",
        txt34: "zapis danych",
        txt35: "Zmień hasło",
        txt36: "Zmień lokalizację",
    },
    login: {
        title: "& Zaloguj się",
        username: "Proszę podać nazwę użytkownika",
        password: "Proszę podać hasło",
        forgetPaw: "Zapomnij hasło",
        registerNow: "Nie ma konta? Zarejestruj się teraz",
        doLogin: "& Zaloguj się",

    },
    register:{
        title: "Zarejestruj się.",
        username: "Wprowadź nazwę użytkownika (6-12 angielskich liter lub cyfr)",
        password: "Wprowadź hasło logowania (6-12 angielskich liter lub cyfr)",
        code: "Wprowadź kod zaproszenia",
        agreement: "Znam i zgadzam się na traktaty umowy o otwarciu rachunku.",
        chooseAgreement: "Zaznacz poniższą umowę otwarcia konta!",
        doRegister: "Zarejestruj się.",

    },
    footer:{
        home : "Strona główna",
        game: "Umów się na spotkanie.",
        video: "Wideo video",
        mine: "Moja kolej.",

    },
    game:{
        title: "Sala rezerwacji",
        all: "-Wszystko.",

    },
    choose:{
        title: "Wybór konkubiny.",
        city: "Urban Daquan",
        rule: "Ta platforma jest najbardziej autentyczna w całej sieci + eskorta biznesowa + pasja w tym samym mieście Aby zapewnić prywatność każdego użytkownika, klienci mogą dołączyć tylko, kontaktując się z recepcjonistą lub starszymi członkami platformy.",
        price: "Proces cenowy",
        resource: "Jakie są dostępne zasoby?",
        character: "Net czerwony, model, stewardesa, delikatna modelka, student, tylko ty nie możesz o tym myśleć, nie możesz",
        scope: "Zakres usług?",
        location: "Bezpłatna broń w tym samym mieście, dowolne miejsce w powietrzu w całym kraju, miasta pierwszego i drugiego poziomu w Chinach, miasta trzeciego poziomu mogą również umówić się na spotkanie, kontaktując się z recepcjonistką.",

    },
    home:{
        recommend: "Zalecane zadanie",
        more: "Widok Więcej",
        popularity: "Ranking popularności",
        hot: "Popularne rekomendacje",

    },
    lottery:{
        jieguo: "Wyniki głosowania",
        jiesu: "Zakończ to.",
        record: "Zapis misji",
        rule: "Zasady gry",
        prompt: "Wskazówki dotyczące gry",
        gameplay: "Wybierz numer z opcjonalnego i wartościowego formularza, aby postawić zakład",
        explain: "Opis zwycięstwa",
        wprole: "Suma trzech liczb loterii wynosi od 11 do 18; Suma wartości od 3 do 10 jest mała;",
        example: "Przykład zakładów",
        option: "Plan zakładów: mały numer loterii: 123, czyli mały i średni",
        task: "Lista zadań",
        money: "Dostępne saldo",
        submit: "Prześlij to.",
        selection: "Aktualny numer wyboru",
        perNote: "Kwota na zakład",
        inputMoney: "Proszę podać kwotę",
        total: "W sumie",
        note: "W sumie",
        statistics: "W sumie",
        empty: "Opróżnij zamówienie.",
        verify: "Potwierdź przesłanie",
        issue: "Numer wydania",
        num: "Numer losowania",
        big: "Jest duży.",
        small: "Mała dziewczynka.",
        single: "Proszę bardzo.",
        double: "-Podwójnie.",
        unselected: "Nie zaznaczono",
        drawLottery: "Loteria zakończona sukcesem, numer wydania:",
        kefu: "Skontaktuj się z administratorem w sprawie zadania!",

    },
    mine:{
        recharge: "Naładuj go.",
        withdraw: "Wycofaj gotówkę.",
        myMoney: "Mój portfel.",
        detail: "Szczegóły",
        balance: "Saldo salda.",
        finance: "Raport osobisty",
        order: "Szczegóły konta",
        record: "Zapis misji",
        my: "Centrum osobiste",
        notice: "Ogłoszenie informacyjne",
        service: "Obsługa klienta online",
        score: "Punkty dla wszystkich",
    },
    setting:{
        title: "Ustawienia:",
        basic: "Podstawowe ustawienia informacji",
        password: "Hasło logowania",
        capital: "Hasło do funduszu",
        lang: "-Język.",
        quit: "Wyjdź z logowania",

    },
    setPayPassword: {
        title: "Ustaw hasło do funduszu",
        opw: "Wprowadź hasło do funduszu",
        tpw: "Wprowadź ponownie hasło do funduszu",

    },
    gameRecord:{
        reject: "odrzucić",
        profit: "zysk",
        place: "złożyć zamówienie",
        title: "Zapis misji",
        amountBet: "Kwota zakładu",
        create: "Czas składania zamówienia",
        update: "Czas rozliczenia",

    },
    video:{
        title: "Teatr wideo",
        play: "Zagraj w to.",

    },
    playVideo:{
        play: "Odtwarzanie drugorzędne",

    },
    setname:{
        title: "Zmień prawdziwe imię i nazwisko",
        name: "Imię i nazwisko.",
        truename: "Wpisz swoje prawdziwe imię i nazwisko",
        hint: "Dla bezpieczeństwa konta prawdziwe imię i nazwisko muszą być zgodne z nazwą powiązanej karty bankowej.",

    },
    setsex:{
        title: "Modyfikacja płci",

    },
    bindCard:{
        title: "Wypełnij kartę odbioru",
        msg: "Wprowadź informacje o karcie odbiorczej",
        hint: "Drodzy użytkownicy, aby zabezpieczyć swoje środki, powiąż swoje prawdziwe imię i nazwisko oraz ustaw hasło wypłaty. Jeśli nazwa jest niezgodna z nazwą konta, nie będziesz w stanie wypłacić pieniędzy.",
        card: "Numer konta (normalny)",
        truecard: "Wprowadź konto (normalne)",
        name: "Nazwa konta (pseudonim)",
        truename: "Podaj nazwę konta (pseudonim)",
        financial_institution: "Nazwa instytucji finansowej",
        truefinancial_institution: "Wprowadź nazwę instytucji finansowej",
        branch: "Nazwa oddziału",
        truebranch: "Podaj nazwę oddziału",
        branch_number: "Numer oddziału",
        truebranch_number: "Wprowadź numer oddziału"
    },
    setbank:{
        title: "Informacje o karcie odbioru",
        bank: "Dodaj kartę bankową",
        hint: "Wskazówka: zawiąż duży bank komercyjny. Jeśli chcesz go zmodyfikować, skontaktuj się z obsługą klienta online.",

    },
    setLoginPassword:{
        title: "Zmień hasło logowania",
        old_password: "Stare hasło.",
        old_password_p: "Proszę podać stare hasło",
        o_new_password: "Nowe hasło",
        o_new_password_p: "Proszę podać nowe hasło",
        t_new_password_p: "Wpisz nowe hasło ponownie",

    },
    infomation:{
        title: "Podstawowe informacje",
        head: "Zdjęcie awatara.",
        select: "Wybierz awatar",
        name: "Prawdziwe imię i nazwisko.",
        sex: "& Płeć",
        man: "Proszę pana.",
        girl: "-Kobieta.",
        unknown: "Nieznany",
        binding: "Informacje o powiązaniu",

    },
    withdraw: {
        title: "Centrum gotówki",
        record: "Rekord wypłaty",
        money: "Kwota wypłaty",
        all: "-Wszystko.",
        min: "Pojedynczy limit: najniższy",
        max: "",
        num: "Wycofanie: jeden dzień wypłaty",
        hint: "Czas przybycia: Ogólny czas przybycia wynosi około 5 minut, a najszybszy czas przybycia wynosi 2 minuty.",
        quota: "Opis limitu",
        balance: "Saldo salda.",
        withdraw: "Wycofaj natychmiast",

    },
    withdrawRecord:{
        desc: "Opis:",
        create: "Czas przesyłania",
        update: "Czas przeglądu",

    },
    personalreport:{
        title: "Raport osobisty",
        profit: "Kwota zysku",
        formula: "Formuła obliczania zysku: wygrana kwota-kwota zadania",
        task: "Kwota zadania",
        recharge: "Ilość doładowania",
        withdrawal: "Kwota wypłaty",
        win_money: "Wygrana kwota",

    },
    service: {
        title: "Obsługa klienta online",
        relation: "Skontaktuj się z nim.",
        hint: "7 * 24 godziny na dobę, aby Ci służyć",

    },
    profile: {
        portrait: "Fotografia",
        video: "Wideo video",
        jianjie:" Profil osobisty",
        liji: "Umów się natychmiast",
        chongdan: "Zakres ładowania",
        fd: "Droga damo.",
        yuyue: "Umów się na spotkanie.",
        archives: "Plik siostry",
        age: "& Wiek",
        height: "-Wysokość.",
        weight: "Waga ciała.",
        cup: "Zakryj kubek.",
        city: "W mieście.",
        area: "Obszar serwisowy",
        item: "Projekt serwisowy"
    },
    msg:{
        "次": "Po raz pierwszy.",
        "用户不存在！": "Użytkownik nie istnieje!",
        "密码错误！": "Błędne hasło!",
        "登录成功！": "Logowanie powiodło się!",
        "邀请码不存在！": "Kod zaproszenia nie istnieje!",
        "用户名位数错误！": "Numer nazwy użytkownika jest nieprawidłowy!",
        "密码位数错误！": "Bity hasła są nieprawidłowe!",
        "用户名不能存在中文！": "Nazwa użytkownika nie może istnieć w języku chińskim!",
        "邀请码不能为空！": "Kod zaproszenia nie może być pusty!",
        "注册成功！": "Rejestracja zakończona sukcesem!",
        "注册失败！": "Rejestracja nie powiodła się!",
        "用户已存在": "Użytkownik już istnieje",
        "刷新成功！": "Odświeżenie powiodło się!",
        "请联系顾问或接待员": "Skontaktuj się z konsultantem lub recepcjonistą",
        "金额错误！": "Zła kwota!",
        "请选号！": "Proszę wybrać numer!",
        "请填写金额!": "Proszę podać kwotę!",
        "余额不足，请联系客服充值！": "Saldo jest niewystarczające, skontaktuj się z obsługą klienta w celu doładowania",
        "没有更多了": "Nic więcej.",
        "没有数据": "Brak danych.",
        "余额不足！": "Niewystarczające saldo!",
        "扣款失败！": "Odliczenie nie powiodło się!",
        "下注异常！": "Zakład jest nienormalny!",
        "投注成功！": "Zakład się powiódł!",
        "参数异常！": "Parametry są nienormalne!",
        "获取中": "Pobierz...",
        "请完成任务单后进入": "Proszę wypełnić listę zadań",
        "请联系客服充值": "Skontaktuj się z obsługą klienta w celu uzupełnienia",
        "请设置收款卡!": "Proszę skonfigurować kartę odbioru!",
        "功能已禁用!": "Funkcja wyłączona!",
        "账号下线": "Konto offline",
        "登录/注册": "Zaloguj się/zarejestruj",
        "登录可享受更多服务！": "Zaloguj się, aby cieszyć się większą liczbą usług!",
        "未设置": "Nie ustawiono",
        "已设置": "Już ustawione",
        "提交": "Prześlij to.",
        "确定": "Na pewno.",
        "请填写完整": "Proszę wypełnić",
        "两次密码不一致！": "Dwa razy niespójne hasła!",
        "设置提现密码成功！": "Ustaw hasło wypłaty pomyślnie!",
        "提现密码已设置，需要修改请联系客服": "Hasło wypłaty zostało ustawione. Aby je zmodyfikować, skontaktuj się z obsługą klienta.",
        "已绑定": "Związane",
        "无": "Nie ma żadnego.",
        "更换头像成功！": "Zastąpienie awatara zakończyło się sukcesem!",
        "更新头像失败！": "Aktualizacja awatara nie powiodła się!",
        "请勿重复设置！": "Nie powtarzaj ustawień!",
        "设置姓名成功！": "Ustaw nazwę pomyślnie!",
        "设置姓名失败！": "Nie udało się ustawić nazwy!",
        "设置性别成功！": "Ustaw sukces płci!",
        "设置性别失败！": "Błąd w ustawianiu płci!",
        "请设置姓名后再绑定银行卡！": "Ustaw swoje imię i nazwisko przed powiązaniem karty bankowej!",
        "请设置提现密码后再绑定银行卡！": "Ustaw hasło wypłaty przed powiązaniem karty bankowej!",
        "确认绑卡": "Potwierdź kartę wiązania",
        "请输入银行卡号！": "Proszę wejść do banku!",
        "请输入银行名称！": "Wpisz nazwę banku!",
        "设置银行卡成功！": "Skonfiguruj kartę bankową pomyślnie!",
        "设置银行卡失败！": "Konfigurowanie karty bankowej nie powiodło się!",
        "旧密码错误": "Błąd starego hasła",
        "修改密码成功！": "Pomyślnie zmień hasło!",
        "修改密码失败！": "Zmiana hasła nie powiodła się!",
        "请填写正确的金额": "Proszę podać prawidłową kwotę",
        "提现金额不能少于：": "Kwota wypłaty gotówki nie może być mniejsza niż:",
        "提现金额不能大于：": "Kwota wypłaty nie może być większa niż:",
        "当日提现次数已用完": "Wycofanie dnia zostało zużyte",
        "提现成功！": "Wycofanie się powiodło!",
        "提现失败！": "Wycofanie się nie powiodło!",
        "金额": "Kwota pieniędzy.",
        "待审核": "Oczekiwanie na recenzję",
        "审核成功": "Przegląd zakończony sukcesem",
        "审核退回": "Przejrzyj zwrot",
        "请充值后观看视频！": "Obejrzyj wideo po doładowaniu!",
        "释放即可刷新": "Zwolnij, aby odświeżyć...",
        "下拉即可刷新": "Rozwiń, aby odświeżyć...",
        "加载中": "Wczytywanie...",

    },
    setLang:{
        title: "Ustaw język"
    },
    lang:{
        zh: "Po chińsku.",
        jp: "Japonia",
        ara: "& Język",
        en: "Po angielsku.",
        spa: "Po hiszpańsku.",
        it: "Włoski",
        de: "Po niemiecku.",
        pt: "Portugalski",
        fra: "Po francusku.",
        nl: "Holenderski",
        id: "Indonezyjski",
        hi: "Hindi",
        kor: "Po koreańsku.",
        tr: "Turecki",
        swe: "Szwedzki",
        ga: "Irlandzki",
        ru: "Po rosyjsku.",
        vie: "Wietnamski",
        th: "Po tajsku.",
        est: "Estoński",
        be: "Białoruski",
        bul: "Bułgarski",
        pl: "Polski",
        ms: "Malajski",

    }
}
export default Pl